import { default as r } from "./icons/App/Colored/AmericanExpress.js";
import { default as a } from "./icons/App/Colored/Android.js";
import { default as f } from "./icons/App/Colored/Aodocs.js";
import { default as u } from "./icons/App/Colored/Apple.js";
import { default as p } from "./icons/App/Colored/Asana.js";
import { default as d } from "./icons/App/Colored/Atlassian.js";
import { default as i } from "./icons/App/Colored/Awesometable.js";
import { default as I } from "./icons/App/Colored/Aws.js";
import { default as C } from "./icons/App/Colored/Azure.js";
import { default as M } from "./icons/App/Colored/Box.js";
import { default as A } from "./icons/App/Colored/Clickup.js";
import { default as S } from "./icons/App/Colored/Confluence.js";
import { default as b } from "./icons/App/Colored/Discord.js";
import { default as D } from "./icons/App/Colored/Dribbble.js";
import { default as B } from "./icons/App/Colored/Dropbox.js";
import { default as T } from "./icons/App/Colored/Elisa.js";
import { default as R } from "./icons/App/Colored/Facebook.js";
import { default as v } from "./icons/App/Colored/Figma.js";
import { default as H } from "./icons/App/Colored/Freshdesk.js";
import { default as U } from "./icons/App/Colored/G2.js";
import { default as W } from "./icons/App/Colored/Github.js";
import { default as N } from "./icons/App/Colored/Gitlab.js";
import { default as Q } from "./icons/App/Colored/Gmail.js";
import { default as K } from "./icons/App/Colored/GoogleAnalytics.js";
import { default as Y } from "./icons/App/Colored/GoogleCalendar.js";
import { default as _ } from "./icons/App/Colored/GoogleChat.js";
import { default as oo } from "./icons/App/Colored/GoogleChromeDino.js";
import { default as ro } from "./icons/App/Colored/GoogleChrome.js";
import { default as ao } from "./icons/App/Colored/GoogleDocs.js";
import { default as fo } from "./icons/App/Colored/GoogleDrive.js";
import { default as uo } from "./icons/App/Colored/GoogleFirebase.js";
import { default as po } from "./icons/App/Colored/GoogleFonts.js";
import { default as mo } from "./icons/App/Colored/GoogleForms.js";
import { default as xo } from "./icons/App/Colored/GoogleHangoutsMeet.js";
import { default as Fo } from "./icons/App/Colored/GoogleHangouts.js";
import { default as ho } from "./icons/App/Colored/GoogleKeep.js";
import { default as Oo } from "./icons/App/Colored/GoogleMaps.js";
import { default as go } from "./icons/App/Colored/GoogleMeet.js";
import { default as ko } from "./icons/App/Colored/GooglePlay.js";
import { default as Po } from "./icons/App/Colored/GoogleSites.js";
import { default as Go } from "./icons/App/Colored/GoogleSlides.js";
import { default as Lo } from "./icons/App/Colored/Google.js";
import { default as wo } from "./icons/App/Colored/Happeo.js";
import { default as yo } from "./icons/App/Colored/Hibob.js";
import { default as Eo } from "./icons/App/Colored/Hubspot.js";
import { default as Vo } from "./icons/App/Colored/Instagram.js";
import { default as Jo } from "./icons/App/Colored/Intercom.js";
import { default as zo } from "./icons/App/Colored/Jabber.js";
import { default as qo } from "./icons/App/Colored/Jira.js";
import { default as Zo } from "./icons/App/Colored/Klarna.js";
import { default as jo } from "./icons/App/Colored/Kustomer.js";
import { default as Xo } from "./icons/App/Colored/Linkedin.js";
import { default as $o } from "./icons/App/Colored/Loopio.js";
import { default as ee } from "./icons/App/Colored/Mailchimp.js";
import { default as te } from "./icons/App/Colored/Mastercard.js";
import { default as le } from "./icons/App/Colored/Maze.js";
import { default as ne } from "./icons/App/Colored/Medium.js";
import { default as ce } from "./icons/App/Colored/Meta.js";
import { default as se } from "./icons/App/Colored/MicrosoftOnedrive.js";
import { default as me } from "./icons/App/Colored/MicrosoftSharePoint.js";
import { default as xe } from "./icons/App/Colored/MicrosoftTeams.js";
import { default as Fe } from "./icons/App/Colored/Microsoft.js";
import { default as he } from "./icons/App/Colored/Miro.js";
import { default as Oe } from "./icons/App/Colored/Okta.js";
import { default as ge } from "./icons/App/Colored/Pendo.js";
import { default as ke } from "./icons/App/Colored/Pinterest.js";
import { default as Pe } from "./icons/App/Colored/Polly.js";
import { default as Ge } from "./icons/App/Colored/Productboard.js";
import { default as Le } from "./icons/App/Colored/Rydoo.js";
import { default as we } from "./icons/App/Colored/Salesforce.js";
import { default as ye } from "./icons/App/Colored/Skype.js";
import { default as Ee } from "./icons/App/Colored/Slack.js";
import { default as Ve } from "./icons/App/Colored/Slite.js";
import { default as Je } from "./icons/App/Colored/Spotify.js";
import { default as ze } from "./icons/App/Colored/Thinkific.js";
import { default as qe } from "./icons/App/Colored/Trello.js";
import { default as Ze } from "./icons/App/Colored/Tripaction.js";
import { default as je } from "./icons/App/Colored/Trustpilot.js";
import { default as Xe } from "./icons/App/Colored/Twitter.js";
import { default as $e } from "./icons/App/Colored/Typeform.js";
import { default as er } from "./icons/App/Colored/Unsplash.js";
import { default as tr } from "./icons/App/Colored/Visa.js";
import { default as lr } from "./icons/App/Colored/WebexMeetings.js";
import { default as nr } from "./icons/App/Colored/WebexTeams.js";
import { default as cr } from "./icons/App/Colored/Webex.js";
import { default as sr } from "./icons/App/Colored/Youtube.js";
import { default as mr } from "./icons/App/Colored/Zendesk.js";
import { default as xr } from "./icons/App/Colored/Zoom.js";
import { default as Fr } from "./icons/App/Monochrome/AmericanExpress.js";
import { default as hr } from "./icons/App/Monochrome/Android.js";
import { default as Or } from "./icons/App/Monochrome/Aodocs.js";
import { default as gr } from "./icons/App/Monochrome/Apple.js";
import { default as kr } from "./icons/App/Monochrome/Asana.js";
import { default as Pr } from "./icons/App/Monochrome/Atlassian.js";
import { default as Gr } from "./icons/App/Monochrome/Awesometable.js";
import { default as Lr } from "./icons/App/Monochrome/Aws.js";
import { default as wr } from "./icons/App/Monochrome/Azure.js";
import { default as yr } from "./icons/App/Monochrome/Box.js";
import { default as Er } from "./icons/App/Monochrome/Clickup.js";
import { default as Vr } from "./icons/App/Monochrome/Confluence.js";
import { default as Jr } from "./icons/App/Monochrome/Discord.js";
import { default as zr } from "./icons/App/Monochrome/Dribbble.js";
import { default as qr } from "./icons/App/Monochrome/Dropbox.js";
import { default as Zr } from "./icons/App/Monochrome/Elisa.js";
import { default as jr } from "./icons/App/Monochrome/Facebook.js";
import { default as Xr } from "./icons/App/Monochrome/Figma.js";
import { default as $r } from "./icons/App/Monochrome/Freshdesk.js";
import { default as et } from "./icons/App/Monochrome/G2.js";
import { default as tt } from "./icons/App/Monochrome/Github.js";
import { default as lt } from "./icons/App/Monochrome/Gitlab.js";
import { default as nt } from "./icons/App/Monochrome/Gmail.js";
import { default as ct } from "./icons/App/Monochrome/GoogleAnalytics.js";
import { default as st } from "./icons/App/Monochrome/GoogleCalendar.js";
import { default as mt } from "./icons/App/Monochrome/GoogleChat.js";
import { default as xt } from "./icons/App/Monochrome/GoogleChromeDino.js";
import { default as Ft } from "./icons/App/Monochrome/GoogleChrome.js";
import { default as ht } from "./icons/App/Monochrome/GoogleDocs.js";
import { default as Ot } from "./icons/App/Monochrome/GoogleDrive.js";
import { default as gt } from "./icons/App/Monochrome/GoogleFirebase.js";
import { default as kt } from "./icons/App/Monochrome/GoogleFonts.js";
import { default as Pt } from "./icons/App/Monochrome/GoogleForms.js";
import { default as Gt } from "./icons/App/Monochrome/GoogleHangoutsMeet.js";
import { default as Lt } from "./icons/App/Monochrome/GoogleHangouts.js";
import { default as wt } from "./icons/App/Monochrome/GoogleKeep.js";
import { default as yt } from "./icons/App/Monochrome/GoogleMaps.js";
import { default as Et } from "./icons/App/Monochrome/GoogleMeet.js";
import { default as Vt } from "./icons/App/Monochrome/GooglePlay.js";
import { default as Jt } from "./icons/App/Monochrome/GoogleSites.js";
import { default as zt } from "./icons/App/Monochrome/GoogleSlides.js";
import { default as qt } from "./icons/App/Monochrome/Google.js";
import { default as Zt } from "./icons/App/Monochrome/Happeo.js";
import { default as jt } from "./icons/App/Monochrome/Hibob.js";
import { default as Xt } from "./icons/App/Monochrome/Hubspot.js";
import { default as $t } from "./icons/App/Monochrome/Instagram.js";
import { default as ea } from "./icons/App/Monochrome/Intercom.js";
import { default as ta } from "./icons/App/Monochrome/Jabber.js";
import { default as la } from "./icons/App/Monochrome/Jira.js";
import { default as na } from "./icons/App/Monochrome/Klarna.js";
import { default as ca } from "./icons/App/Monochrome/Kustomer.js";
import { default as sa } from "./icons/App/Monochrome/Linkedin.js";
import { default as ma } from "./icons/App/Monochrome/Loopio.js";
import { default as xa } from "./icons/App/Monochrome/Mailchimp.js";
import { default as Fa } from "./icons/App/Monochrome/Mastercard.js";
import { default as ha } from "./icons/App/Monochrome/Maze.js";
import { default as Oa } from "./icons/App/Monochrome/Medium.js";
import { default as ga } from "./icons/App/Monochrome/Meta.js";
import { default as ka } from "./icons/App/Monochrome/MicrosoftOnedrive.js";
import { default as Pa } from "./icons/App/Monochrome/MicrosoftSharePoint.js";
import { default as Ga } from "./icons/App/Monochrome/MicrosoftTeams.js";
import { default as La } from "./icons/App/Monochrome/Microsoft.js";
import { default as wa } from "./icons/App/Monochrome/Miro.js";
import { default as ya } from "./icons/App/Monochrome/Okta.js";
import { default as Ea } from "./icons/App/Monochrome/Pendo.js";
import { default as Va } from "./icons/App/Monochrome/Pinterest.js";
import { default as Ja } from "./icons/App/Monochrome/Polly.js";
import { default as za } from "./icons/App/Monochrome/Productboard.js";
import { default as qa } from "./icons/App/Monochrome/Rydoo.js";
import { default as Za } from "./icons/App/Monochrome/Salesforce.js";
import { default as ja } from "./icons/App/Monochrome/Skype.js";
import { default as Xa } from "./icons/App/Monochrome/Slack.js";
import { default as $a } from "./icons/App/Monochrome/Slite.js";
import { default as el } from "./icons/App/Monochrome/Spotify.js";
import { default as tl } from "./icons/App/Monochrome/Thinkific.js";
import { default as ll } from "./icons/App/Monochrome/Trello.js";
import { default as nl } from "./icons/App/Monochrome/Tripaction.js";
import { default as cl } from "./icons/App/Monochrome/Trustpilot.js";
import { default as sl } from "./icons/App/Monochrome/Twitter.js";
import { default as ml } from "./icons/App/Monochrome/Typeform.js";
import { default as xl } from "./icons/App/Monochrome/Unsplash.js";
import { default as Fl } from "./icons/App/Monochrome/Visa.js";
import { default as hl } from "./icons/App/Monochrome/WebexMeetings.js";
import { default as Ol } from "./icons/App/Monochrome/WebexTeams.js";
import { default as gl } from "./icons/App/Monochrome/Webex.js";
import { default as kl } from "./icons/App/Monochrome/Youtube.js";
import { default as Pl } from "./icons/App/Monochrome/Zendesk.js";
import { default as Gl } from "./icons/App/Monochrome/Zoom.js";
import { default as Ll } from "./icons/File/Colored/AdobeAcrobat.js";
import { default as wl } from "./icons/File/Colored/AdobeAftereffect.js";
import { default as yl } from "./icons/File/Colored/AdobeAnimate.js";
import { default as El } from "./icons/File/Colored/AdobeIllustrator.js";
import { default as Vl } from "./icons/File/Colored/AdobeIndesign.js";
import { default as Jl } from "./icons/File/Colored/AdobePhotoshop.js";
import { default as zl } from "./icons/File/Colored/AdobePremiere.js";
import { default as ql } from "./icons/File/Colored/Channels.js";
import { default as Zl } from "./icons/File/Colored/DropboxAppcenter.js";
import { default as jl } from "./icons/File/Colored/DropboxBackup.js";
import { default as Xl } from "./icons/File/Colored/DropboxCapture.js";
import { default as $l } from "./icons/File/Colored/DropboxDocsend.js";
import { default as ef } from "./icons/File/Colored/DropboxInvoice.js";
import { default as tf } from "./icons/File/Colored/DropboxPaper.js";
import { default as lf } from "./icons/File/Colored/DropboxPasswords.js";
import { default as nf } from "./icons/File/Colored/DropboxReplay.js";
import { default as cf } from "./icons/File/Colored/DropboxSign.js";
import { default as sf } from "./icons/File/Colored/DropboxTransfer.js";
import { default as mf } from "./icons/File/Colored/File.js";
import { default as If } from "./icons/File/Colored/FolderMultiple.js";
import { default as Cf } from "./icons/File/Colored/Folder.js";
import { default as Mf } from "./icons/File/Colored/GoogleAcrobat.js";
import { default as Af } from "./icons/File/Colored/GoogleAudio.js";
import { default as Sf } from "./icons/File/Colored/GoogleCode.js";
import { default as bf } from "./icons/File/Colored/GoogleDocs.js";
import { default as Df } from "./icons/File/Colored/GoogleDocumentsText.js";
import { default as Bf } from "./icons/File/Colored/GoogleDrawings.js";
import { default as Tf } from "./icons/File/Colored/GoogleFont.js";
import { default as Rf } from "./icons/File/Colored/GoogleForms.js";
import { default as vf } from "./icons/File/Colored/GoogleImage.js";
import { default as Hf } from "./icons/File/Colored/GoogleJamboard.js";
import { default as Uf } from "./icons/File/Colored/GoogleMaps.js";
import { default as Wf } from "./icons/File/Colored/GoogleMusic.js";
import { default as Nf } from "./icons/File/Colored/GooglePdf.js";
import { default as Qf } from "./icons/File/Colored/GoogleSheets.js";
import { default as Kf } from "./icons/File/Colored/GoogleSites.js";
import { default as Yf } from "./icons/File/Colored/GoogleSlides.js";
import { default as _f } from "./icons/File/Colored/GoogleVideoAlt.js";
import { default as on } from "./icons/File/Colored/GoogleVideo.js";
import { default as rn } from "./icons/File/Colored/GoogleZip.js";
import { default as an } from "./icons/File/Colored/Image.js";
import { default as fn } from "./icons/File/Colored/JiraAccessRequest.js";
import { default as un } from "./icons/File/Colored/JiraBugAlt.js";
import { default as pn } from "./icons/File/Colored/JiraBug.js";
import { default as dn } from "./icons/File/Colored/JiraConcern.js";
import { default as xn } from "./icons/File/Colored/JiraDefect.js";
import { default as Fn } from "./icons/File/Colored/JiraDesignTask.js";
import { default as hn } from "./icons/File/Colored/JiraDevelopmentTask.js";
import { default as On } from "./icons/File/Colored/JiraDocumentation.js";
import { default as gn } from "./icons/File/Colored/JiraEpic.js";
import { default as kn } from "./icons/File/Colored/JiraImprovement.js";
import { default as Pn } from "./icons/File/Colored/JiraNewFeature.js";
import { default as Gn } from "./icons/File/Colored/JiraQuestion.js";
import { default as Ln } from "./icons/File/Colored/JiraRemoveFeature.js";
import { default as wn } from "./icons/File/Colored/JiraRequirement.js";
import { default as yn } from "./icons/File/Colored/JiraSalesRequest.js";
import { default as En } from "./icons/File/Colored/JiraStory.js";
import { default as Vn } from "./icons/File/Colored/JiraSubTask.js";
import { default as Jn } from "./icons/File/Colored/JiraSuggestion.js";
import { default as zn } from "./icons/File/Colored/JiraTask.js";
import { default as qn } from "./icons/File/Colored/Link.js";
import { default as Zn } from "./icons/File/Colored/MicrosoftAccessAlt.js";
import { default as jn } from "./icons/File/Colored/MicrosoftAccess.js";
import { default as Xn } from "./icons/File/Colored/MicrosoftExcelAlt.js";
import { default as $n } from "./icons/File/Colored/MicrosoftExcel.js";
import { default as eu } from "./icons/File/Colored/MicrosoftExchangeAlt.js";
import { default as tu } from "./icons/File/Colored/MicrosoftExchange.js";
import { default as lu } from "./icons/File/Colored/MicrosoftFolder.js";
import { default as nu } from "./icons/File/Colored/MicrosoftForms1.js";
import { default as cu } from "./icons/File/Colored/MicrosoftForms.js";
import { default as su } from "./icons/File/Colored/MicrosoftLibrary.js";
import { default as mu } from "./icons/File/Colored/MicrosoftOnenoteAlt.js";
import { default as xu } from "./icons/File/Colored/MicrosoftOnenote.js";
import { default as Fu } from "./icons/File/Colored/MicrosoftOutlookAlt.js";
import { default as hu } from "./icons/File/Colored/MicrosoftOutlook.js";
import { default as Ou } from "./icons/File/Colored/MicrosoftPowerpointAlt.js";
import { default as gu } from "./icons/File/Colored/MicrosoftPowerpoint.js";
import { default as ku } from "./icons/File/Colored/MicrosoftPublisherAlt.js";
import { default as Pu } from "./icons/File/Colored/MicrosoftPublisher.js";
import { default as Gu } from "./icons/File/Colored/MicrosoftWordAlt.js";
import { default as Lu } from "./icons/File/Colored/MicrosoftWord.js";
import { default as wu } from "./icons/File/Colored/MicrosoftYammerAlt.js";
import { default as yu } from "./icons/File/Colored/MicrosoftYammer.js";
import { default as Eu } from "./icons/File/Colored/Pages.js";
import { default as Vu } from "./icons/File/Colored/SalesforceAccount.js";
import { default as Ju } from "./icons/File/Colored/SalesforceContacts.js";
import { default as zu } from "./icons/File/Colored/SalesforceLead.js";
import { default as qu } from "./icons/File/Colored/SalesforceOpportunity.js";
import { default as Zu } from "./icons/File/Colored/Sound.js";
import { default as ju } from "./icons/File/Monochrome/AdobeAcrobat.js";
import { default as Xu } from "./icons/File/Monochrome/AdobeAftereffect.js";
import { default as $u } from "./icons/File/Monochrome/AdobeAnimate.js";
import { default as ec } from "./icons/File/Monochrome/AdobeIllustrator.js";
import { default as tc } from "./icons/File/Monochrome/AdobeIndesign.js";
import { default as lc } from "./icons/File/Monochrome/AdobePhotoshop.js";
import { default as nc } from "./icons/File/Monochrome/AdobePremiere.js";
import { default as cc } from "./icons/File/Monochrome/Channels.js";
import { default as sc } from "./icons/File/Monochrome/DropboxAppcenter.js";
import { default as mc } from "./icons/File/Monochrome/DropboxBackup.js";
import { default as xc } from "./icons/File/Monochrome/DropboxCapture.js";
import { default as Fc } from "./icons/File/Monochrome/DropboxDocsend.js";
import { default as hc } from "./icons/File/Monochrome/DropboxInvoice.js";
import { default as Oc } from "./icons/File/Monochrome/DropboxPaper.js";
import { default as gc } from "./icons/File/Monochrome/DropboxPasswords.js";
import { default as kc } from "./icons/File/Monochrome/DropboxReplay.js";
import { default as Pc } from "./icons/File/Monochrome/DropboxSign.js";
import { default as Gc } from "./icons/File/Monochrome/DropboxTransfer.js";
import { default as Lc } from "./icons/File/Monochrome/File.js";
import { default as wc } from "./icons/File/Monochrome/FolderMultiple.js";
import { default as yc } from "./icons/File/Monochrome/Folder.js";
import { default as Ec } from "./icons/File/Monochrome/GoogleAcrobat.js";
import { default as Vc } from "./icons/File/Monochrome/GoogleAudio.js";
import { default as Jc } from "./icons/File/Monochrome/GoogleCode.js";
import { default as zc } from "./icons/File/Monochrome/GoogleDocs.js";
import { default as qc } from "./icons/File/Monochrome/GoogleDocumentsText.js";
import { default as Zc } from "./icons/File/Monochrome/GoogleDrawings.js";
import { default as jc } from "./icons/File/Monochrome/GoogleFont.js";
import { default as Xc } from "./icons/File/Monochrome/GoogleForms.js";
import { default as $c } from "./icons/File/Monochrome/GoogleImage.js";
import { default as ep } from "./icons/File/Monochrome/GoogleJamboard.js";
import { default as tp } from "./icons/File/Monochrome/GoogleMaps.js";
import { default as lp } from "./icons/File/Monochrome/GoogleMusic.js";
import { default as np } from "./icons/File/Monochrome/GooglePdf.js";
import { default as cp } from "./icons/File/Monochrome/GoogleSheets.js";
import { default as sp } from "./icons/File/Monochrome/GoogleSites.js";
import { default as mp } from "./icons/File/Monochrome/GoogleSlides.js";
import { default as xp } from "./icons/File/Monochrome/GoogleVideoAlt.js";
import { default as Fp } from "./icons/File/Monochrome/GoogleVideo.js";
import { default as hp } from "./icons/File/Monochrome/GoogleZip.js";
import { default as Op } from "./icons/File/Monochrome/Image.js";
import { default as gp } from "./icons/File/Monochrome/JiraAccessRequest.js";
import { default as kp } from "./icons/File/Monochrome/JiraBugAlt.js";
import { default as Pp } from "./icons/File/Monochrome/JiraBug.js";
import { default as Gp } from "./icons/File/Monochrome/JiraConcern.js";
import { default as Lp } from "./icons/File/Monochrome/JiraDefect.js";
import { default as wp } from "./icons/File/Monochrome/JiraDesignTask.js";
import { default as yp } from "./icons/File/Monochrome/JiraDevelopmentTask.js";
import { default as Ep } from "./icons/File/Monochrome/JiraDocumentation.js";
import { default as Vp } from "./icons/File/Monochrome/JiraEpic.js";
import { default as Jp } from "./icons/File/Monochrome/JiraImprovement.js";
import { default as zp } from "./icons/File/Monochrome/JiraNewFeature.js";
import { default as qp } from "./icons/File/Monochrome/JiraQuestion.js";
import { default as Zp } from "./icons/File/Monochrome/JiraRemoveFeature.js";
import { default as jp } from "./icons/File/Monochrome/JiraRequirement.js";
import { default as Xp } from "./icons/File/Monochrome/JiraSalesRequest.js";
import { default as $p } from "./icons/File/Monochrome/JiraStory.js";
import { default as es } from "./icons/File/Monochrome/JiraSubTask.js";
import { default as ts } from "./icons/File/Monochrome/JiraSuggestion.js";
import { default as ls } from "./icons/File/Monochrome/JiraTask.js";
import { default as ns } from "./icons/File/Monochrome/Link.js";
import { default as cs } from "./icons/File/Monochrome/MicrosoftAccessAlt.js";
import { default as ss } from "./icons/File/Monochrome/MicrosoftAccess.js";
import { default as ms } from "./icons/File/Monochrome/MicrosoftExcelAlt.js";
import { default as xs } from "./icons/File/Monochrome/MicrosoftExcel.js";
import { default as Fs } from "./icons/File/Monochrome/MicrosoftExchangeAlt.js";
import { default as hs } from "./icons/File/Monochrome/MicrosoftExchange.js";
import { default as Os } from "./icons/File/Monochrome/MicrosoftFolder.js";
import { default as gs } from "./icons/File/Monochrome/MicrosoftForms1.js";
import { default as ks } from "./icons/File/Monochrome/MicrosoftForms.js";
import { default as Ps } from "./icons/File/Monochrome/MicrosoftLibrary.js";
import { default as Gs } from "./icons/File/Monochrome/MicrosoftOnenoteAlt.js";
import { default as Ls } from "./icons/File/Monochrome/MicrosoftOnenote.js";
import { default as ws } from "./icons/File/Monochrome/MicrosoftOutlookAlt.js";
import { default as ys } from "./icons/File/Monochrome/MicrosoftOutlook.js";
import { default as Es } from "./icons/File/Monochrome/MicrosoftPowerpointAlt.js";
import { default as Vs } from "./icons/File/Monochrome/MicrosoftPowerpoint.js";
import { default as Js } from "./icons/File/Monochrome/MicrosoftPublisherAlt.js";
import { default as zs } from "./icons/File/Monochrome/MicrosoftPublisher.js";
import { default as qs } from "./icons/File/Monochrome/MicrosoftWordAlt.js";
import { default as Zs } from "./icons/File/Monochrome/MicrosoftWord.js";
import { default as js } from "./icons/File/Monochrome/MicrosoftYammerAlt.js";
import { default as Xs } from "./icons/File/Monochrome/MicrosoftYammer.js";
import { default as $s } from "./icons/File/Monochrome/Pages.js";
import { default as ed } from "./icons/File/Monochrome/SalesforceAccount.js";
import { default as td } from "./icons/File/Monochrome/SalesforceContacts.js";
import { default as ld } from "./icons/File/Monochrome/SalesforceLead.js";
import { default as nd } from "./icons/File/Monochrome/SalesforceOpportunity.js";
import { default as cd } from "./icons/File/Monochrome/Sound.js";
import { default as sd } from "./icons/Flag/China.js";
import { default as md } from "./icons/Flag/Finland.js";
import { default as xd } from "./icons/Flag/France.js";
import { default as Fd } from "./icons/Flag/Germany.js";
import { default as hd } from "./icons/Flag/Italy.js";
import { default as Od } from "./icons/Flag/Japan.js";
import { default as gd } from "./icons/Flag/Netherland.js";
import { default as kd } from "./icons/Flag/Norway.js";
import { default as Pd } from "./icons/Flag/Poland.js";
import { default as Gd } from "./icons/Flag/Portugal.js";
import { default as Ld } from "./icons/Flag/Spain.js";
import { default as wd } from "./icons/Flag/Sweden.js";
import { default as yd } from "./icons/Flag/UnitedKingdom.js";
import { default as Ed } from "./icons/Flag/UnitedStates.js";
import { default as Vd } from "./icons/Flag/Wales.js";
import { default as Jd } from "./icons/Symbol/Fill/AccountCircle.js";
import { default as zd } from "./icons/Symbol/Fill/AccountClipboard.js";
import { default as qd } from "./icons/Symbol/Fill/AccountCog.js";
import { default as Zd } from "./icons/Symbol/Fill/AccountMinus.js";
import { default as jd } from "./icons/Symbol/Fill/AccountMultipleMinus.js";
import { default as Xd } from "./icons/Symbol/Fill/AccountMultipleOff.js";
import { default as $d } from "./icons/Symbol/Fill/AccountMultiplePlus.js";
import { default as em } from "./icons/Symbol/Fill/AccountMultiple.js";
import { default as tm } from "./icons/Symbol/Fill/AccountPlus.js";
import { default as lm } from "./icons/Symbol/Fill/AccountSquare.js";
import { default as nm } from "./icons/Symbol/Fill/Account.js";
import { default as cm } from "./icons/Symbol/Fill/AlarmOff.js";
import { default as sm } from "./icons/Symbol/Fill/Alarm.js";
import { default as mm } from "./icons/Symbol/Fill/AlertBell.js";
import { default as xm } from "./icons/Symbol/Fill/AlertCircle.js";
import { default as Fm } from "./icons/Symbol/Fill/AlertDecagram.js";
import { default as hm } from "./icons/Symbol/Fill/AlertMapMarker.js";
import { default as Om } from "./icons/Symbol/Fill/AlertOctagon.js";
import { default as gm } from "./icons/Symbol/Fill/AlertRhombus.js";
import { default as km } from "./icons/Symbol/Fill/AlertShield.js";
import { default as Pm } from "./icons/Symbol/Fill/AlertTriangle.js";
import { default as Gm } from "./icons/Symbol/Fill/ArView.js";
import { default as Lm } from "./icons/Symbol/Fill/Archive.js";
import { default as wm } from "./icons/Symbol/Fill/Article.js";
import { default as ym } from "./icons/Symbol/Fill/Backspace.js";
import { default as Em } from "./icons/Symbol/Fill/BadgeLg.js";
import { default as Vm } from "./icons/Symbol/Fill/BadgeMd.js";
import { default as Jm } from "./icons/Symbol/Fill/BadgeSm.js";
import { default as zm } from "./icons/Symbol/Fill/Bank.js";
import { default as qm } from "./icons/Symbol/Fill/Basketball.js";
import { default as Zm } from "./icons/Symbol/Fill/BellActive.js";
import { default as jm } from "./icons/Symbol/Fill/BellCircle.js";
import { default as Xm } from "./icons/Symbol/Fill/BellClock.js";
import { default as $m } from "./icons/Symbol/Fill/BellEdit.js";
import { default as ei } from "./icons/Symbol/Fill/BellOff.js";
import { default as ti } from "./icons/Symbol/Fill/BellPlus.js";
import { default as li } from "./icons/Symbol/Fill/Bell.js";
import { default as ni } from "./icons/Symbol/Fill/Bolt.js";
import { default as ci } from "./icons/Symbol/Fill/BookOpenPage.js";
import { default as si } from "./icons/Symbol/Fill/BookOpenText.js";
import { default as mi } from "./icons/Symbol/Fill/BookOpen.js";
import { default as xi } from "./icons/Symbol/Fill/BookmarkMultiple.js";
import { default as Fi } from "./icons/Symbol/Fill/BookmarkOff.js";
import { default as hi } from "./icons/Symbol/Fill/BookmarkPlus.js";
import { default as Oi } from "./icons/Symbol/Fill/Bookmark.js";
import { default as gi } from "./icons/Symbol/Fill/BriefcaseAlt.js";
import { default as ki } from "./icons/Symbol/Fill/Brightness4.js";
import { default as Pi } from "./icons/Symbol/Fill/Brightness5.js";
import { default as Gi } from "./icons/Symbol/Fill/Brightness6.js";
import { default as Li } from "./icons/Symbol/Fill/Brightness7.js";
import { default as wi } from "./icons/Symbol/Fill/Brush.js";
import { default as yi } from "./icons/Symbol/Fill/Bug.js";
import { default as Ei } from "./icons/Symbol/Fill/BullhornHigh.js";
import { default as Vi } from "./icons/Symbol/Fill/BullhornOff.js";
import { default as Ji } from "./icons/Symbol/Fill/Bullhorn.js";
import { default as zi } from "./icons/Symbol/Fill/Bus.js";
import { default as qi } from "./icons/Symbol/Fill/Cake.js";
import { default as Zi } from "./icons/Symbol/Fill/CalendarBlank.js";
import { default as ji } from "./icons/Symbol/Fill/CalendarClock.js";
import { default as Xi } from "./icons/Symbol/Fill/CalendarClose.js";
import { default as $i } from "./icons/Symbol/Fill/CalendarEdit.js";
import { default as ex } from "./icons/Symbol/Fill/CalendarEvent.js";
import { default as tx } from "./icons/Symbol/Fill/CalendarMonth.js";
import { default as lx } from "./icons/Symbol/Fill/CalendarPlus.js";
import { default as nx } from "./icons/Symbol/Fill/CalendarScheduled.js";
import { default as cx } from "./icons/Symbol/Fill/CalendarToday.js";
import { default as sx } from "./icons/Symbol/Fill/CalendarWeek.js";
import { default as mx } from "./icons/Symbol/Fill/CameraPlus.js";
import { default as xx } from "./icons/Symbol/Fill/Camera.js";
import { default as Fx } from "./icons/Symbol/Fill/Car.js";
import { default as hx } from "./icons/Symbol/Fill/Cart.js";
import { default as Ox } from "./icons/Symbol/Fill/CellphoneAndroid.js";
import { default as gx } from "./icons/Symbol/Fill/CellphoneCog.js";
import { default as kx } from "./icons/Symbol/Fill/CellphoneIos.js";
import { default as Px } from "./icons/Symbol/Fill/Cellphone.js";
import { default as Gx } from "./icons/Symbol/Fill/ChartArea.js";
import { default as Lx } from "./icons/Symbol/Fill/ChartBarSquare.js";
import { default as wx } from "./icons/Symbol/Fill/ChartBubble.js";
import { default as yx } from "./icons/Symbol/Fill/CheckAlarm.js";
import { default as Ex } from "./icons/Symbol/Fill/CheckCalendar.js";
import { default as Vx } from "./icons/Symbol/Fill/CheckCircle.js";
import { default as Jx } from "./icons/Symbol/Fill/CheckCloud.js";
import { default as zx } from "./icons/Symbol/Fill/CheckDecagram.js";
import { default as qx } from "./icons/Symbol/Fill/CheckMapMarker.js";
import { default as Zx } from "./icons/Symbol/Fill/CheckShield.js";
import { default as jx } from "./icons/Symbol/Fill/CheckboxBlankMultiple.js";
import { default as Xx } from "./icons/Symbol/Fill/CheckboxBlank.js";
import { default as $x } from "./icons/Symbol/Fill/CheckboxMarkedMultiple.js";
import { default as eI } from "./icons/Symbol/Fill/CheckboxMarked.js";
import { default as tI } from "./icons/Symbol/Fill/ChevronDownCircle.js";
import { default as lI } from "./icons/Symbol/Fill/ChevronLeftCircle.js";
import { default as nI } from "./icons/Symbol/Fill/ChevronRightCircle.js";
import { default as cI } from "./icons/Symbol/Fill/ChevronUpCircle.js";
import { default as sI } from "./icons/Symbol/Fill/Chrome.js";
import { default as mI } from "./icons/Symbol/Fill/CircleOff.js";
import { default as xI } from "./icons/Symbol/Fill/CircleSlice1.js";
import { default as FI } from "./icons/Symbol/Fill/CircleSlice2.js";
import { default as hI } from "./icons/Symbol/Fill/CircleSlice3.js";
import { default as OI } from "./icons/Symbol/Fill/CircleSlice4.js";
import { default as gI } from "./icons/Symbol/Fill/CircleSlice5.js";
import { default as kI } from "./icons/Symbol/Fill/CircleSlice6.js";
import { default as PI } from "./icons/Symbol/Fill/CircleSlice7.js";
import { default as GI } from "./icons/Symbol/Fill/CircleSlice8.js";
import { default as LI } from "./icons/Symbol/Fill/Circle.js";
import { default as wI } from "./icons/Symbol/Fill/ClockMultiple.js";
import { default as yI } from "./icons/Symbol/Fill/Clock.js";
import { default as EI } from "./icons/Symbol/Fill/CloseCalendar.js";
import { default as VI } from "./icons/Symbol/Fill/CloseCircle.js";
import { default as JI } from "./icons/Symbol/Fill/CloudAlt.js";
import { default as zI } from "./icons/Symbol/Fill/CloudDownload.js";
import { default as qI } from "./icons/Symbol/Fill/CloudOff.js";
import { default as ZI } from "./icons/Symbol/Fill/CloudUpload.js";
import { default as jI } from "./icons/Symbol/Fill/Cloud.js";
import { default as XI } from "./icons/Symbol/Fill/CodeSquare.js";
import { default as $I } from "./icons/Symbol/Fill/CogMultiple.js";
import { default as eF } from "./icons/Symbol/Fill/Cog.js";
import { default as tF } from "./icons/Symbol/Fill/CompassOff.js";
import { default as lF } from "./icons/Symbol/Fill/Compass.js";
import { default as nF } from "./icons/Symbol/Fill/ContentCopyIos.js";
import { default as cF } from "./icons/Symbol/Fill/ContentCopy.js";
import { default as sF } from "./icons/Symbol/Fill/ContentCut.js";
import { default as mF } from "./icons/Symbol/Fill/ContentSave.js";
import { default as xF } from "./icons/Symbol/Fill/CrosshairsGps.js";
import { default as FF } from "./icons/Symbol/Fill/CurrencyDollarCircle.js";
import { default as hF } from "./icons/Symbol/Fill/DataTableStar.js";
import { default as OF } from "./icons/Symbol/Fill/DataTable.js";
import { default as gF } from "./icons/Symbol/Fill/Database.js";
import { default as kF } from "./icons/Symbol/Fill/Delete.js";
import { default as PF } from "./icons/Symbol/Fill/DevicesOff.js";
import { default as GF } from "./icons/Symbol/Fill/Devices.js";
import { default as LF } from "./icons/Symbol/Fill/DoorMeetingRoom.js";
import { default as wF } from "./icons/Symbol/Fill/DotsMessage.js";
import { default as yF } from "./icons/Symbol/Fill/DropOff.js";
import { default as EF } from "./icons/Symbol/Fill/Drop.js";
import { default as VF } from "./icons/Symbol/Fill/Edit.js";
import { default as JF } from "./icons/Symbol/Fill/EmojiOff.js";
import { default as zF } from "./icons/Symbol/Fill/EmoticonAngry.js";
import { default as qF } from "./icons/Symbol/Fill/EmoticonClose.js";
import { default as ZF } from "./icons/Symbol/Fill/EmoticonDead.js";
import { default as jF } from "./icons/Symbol/Fill/EmoticonExcited.js";
import { default as XF } from "./icons/Symbol/Fill/EmoticonFrown.js";
import { default as $F } from "./icons/Symbol/Fill/EmoticonHappy.js";
import { default as eC } from "./icons/Symbol/Fill/EmoticonNeutral.js";
import { default as tC } from "./icons/Symbol/Fill/EmoticonPlus.js";
import { default as lC } from "./icons/Symbol/Fill/EmoticonSad.js";
import { default as nC } from "./icons/Symbol/Fill/Emoticon.js";
import { default as cC } from "./icons/Symbol/Fill/ErlenmeyerFlaskFull.js";
import { default as sC } from "./icons/Symbol/Fill/ErlenmeyerFlask.js";
import { default as mC } from "./icons/Symbol/Fill/EyeOff.js";
import { default as xC } from "./icons/Symbol/Fill/Eye.js";
import { default as FC } from "./icons/Symbol/Fill/FileClose.js";
import { default as hC } from "./icons/Symbol/Fill/FileDocument.js";
import { default as OC } from "./icons/Symbol/Fill/FileEye.js";
import { default as gC } from "./icons/Symbol/Fill/FileMultiple.js";
import { default as kC } from "./icons/Symbol/Fill/FilePlus.js";
import { default as PC } from "./icons/Symbol/Fill/FileReply.js";
import { default as GC } from "./icons/Symbol/Fill/FileSearch.js";
import { default as LC } from "./icons/Symbol/Fill/FileUpload.js";
import { default as wC } from "./icons/Symbol/Fill/File.js";
import { default as yC } from "./icons/Symbol/Fill/FilterOff.js";
import { default as EC } from "./icons/Symbol/Fill/Filter.js";
import { default as VC } from "./icons/Symbol/Fill/Flag.js";
import { default as JC } from "./icons/Symbol/Fill/Flower.js";
import { default as zC } from "./icons/Symbol/Fill/FolderClock.js";
import { default as qC } from "./icons/Symbol/Fill/FolderMultiple.js";
import { default as ZC } from "./icons/Symbol/Fill/FolderOff.js";
import { default as jC } from "./icons/Symbol/Fill/FolderUpload.js";
import { default as XC } from "./icons/Symbol/Fill/Folder.js";
import { default as $C } from "./icons/Symbol/Fill/FoodOff.js";
import { default as eh } from "./icons/Symbol/Fill/Food.js";
import { default as th } from "./icons/Symbol/Fill/Football.js";
import { default as lh } from "./icons/Symbol/Fill/FormatColorHighlight.js";
import { default as nh } from "./icons/Symbol/Fill/FormatImageCaption.js";
import { default as ch } from "./icons/Symbol/Fill/FormatImageCenter.js";
import { default as sh } from "./icons/Symbol/Fill/FormatImageCoverAlt.js";
import { default as mh } from "./icons/Symbol/Fill/FormatImageCover.js";
import { default as xh } from "./icons/Symbol/Fill/FormatImageLeftAlt.js";
import { default as Fh } from "./icons/Symbol/Fill/FormatImageLeft.js";
import { default as hh } from "./icons/Symbol/Fill/FormatImageOverflow.js";
import { default as Oh } from "./icons/Symbol/Fill/FormatImageRightAlt.js";
import { default as gh } from "./icons/Symbol/Fill/FormatImageRight.js";
import { default as kh } from "./icons/Symbol/Fill/FormatQuoteClose.js";
import { default as Ph } from "./icons/Symbol/Fill/FormatQuoteOpen.js";
import { default as Gh } from "./icons/Symbol/Fill/Forum.js";
import { default as Lh } from "./icons/Symbol/Fill/GaugeHigh.js";
import { default as wh } from "./icons/Symbol/Fill/GaugeLow.js";
import { default as yh } from "./icons/Symbol/Fill/GetApp.js";
import { default as Eh } from "./icons/Symbol/Fill/GifSquare.js";
import { default as Vh } from "./icons/Symbol/Fill/GoogleDrivePlus.js";
import { default as Jh } from "./icons/Symbol/Fill/GoogleDrive.js";
import { default as zh } from "./icons/Symbol/Fill/Group.js";
import { default as qh } from "./icons/Symbol/Fill/HammerScrewdriver.js";
import { default as Zh } from "./icons/Symbol/Fill/HashtagSquare.js";
import { default as jh } from "./icons/Symbol/Fill/Headphones.js";
import { default as Xh } from "./icons/Symbol/Fill/HeadsetOff.js";
import { default as $h } from "./icons/Symbol/Fill/Headset.js";
import { default as eM } from "./icons/Symbol/Fill/Heart.js";
import { default as tM } from "./icons/Symbol/Fill/HelpCircle.js";
import { default as lM } from "./icons/Symbol/Fill/HelpTooltip.js";
import { default as nM } from "./icons/Symbol/Fill/HighlightMarker.js";
import { default as cM } from "./icons/Symbol/Fill/Home.js";
import { default as sM } from "./icons/Symbol/Fill/ImageAlt.js";
import { default as mM } from "./icons/Symbol/Fill/ImageBroken.js";
import { default as xM } from "./icons/Symbol/Fill/ImageFilterCenterFocusStrong.js";
import { default as FM } from "./icons/Symbol/Fill/ImageFilterCenterFocusWeak.js";
import { default as hM } from "./icons/Symbol/Fill/ImageMultiple.js";
import { default as OM } from "./icons/Symbol/Fill/ImagePlusAlt.js";
import { default as gM } from "./icons/Symbol/Fill/ImagePlus.js";
import { default as kM } from "./icons/Symbol/Fill/ImageStar.js";
import { default as PM } from "./icons/Symbol/Fill/Image.js";
import { default as GM } from "./icons/Symbol/Fill/InboxMultiple.js";
import { default as LM } from "./icons/Symbol/Fill/Inbox.js";
import { default as wM } from "./icons/Symbol/Fill/InformationCircle.js";
import { default as yM } from "./icons/Symbol/Fill/InformationShield.js";
import { default as EM } from "./icons/Symbol/Fill/Keyboard.js";
import { default as VM } from "./icons/Symbol/Fill/LabelAlt.js";
import { default as JM } from "./icons/Symbol/Fill/LabelOff.js";
import { default as zM } from "./icons/Symbol/Fill/Label.js";
import { default as qM } from "./icons/Symbol/Fill/LaptopMac.js";
import { default as ZM } from "./icons/Symbol/Fill/LayerMultipleMinus.js";
import { default as jM } from "./icons/Symbol/Fill/LayerMultipleOff.js";
import { default as XM } from "./icons/Symbol/Fill/LayerMultiplePlus.js";
import { default as $M } from "./icons/Symbol/Fill/LayerMultiple.js";
import { default as eO } from "./icons/Symbol/Fill/Leaf.js";
import { default as tO } from "./icons/Symbol/Fill/LightbulbAlt.js";
import { default as lO } from "./icons/Symbol/Fill/LightbulbMelangeAlt.js";
import { default as nO } from "./icons/Symbol/Fill/LightbulbMelange.js";
import { default as cO } from "./icons/Symbol/Fill/Lightbulb.js";
import { default as sO } from "./icons/Symbol/Fill/LockOpen.js";
import { default as mO } from "./icons/Symbol/Fill/Lock.js";
import { default as xO } from "./icons/Symbol/Fill/MagicWand.js";
import { default as FO } from "./icons/Symbol/Fill/MailArrowRight.js";
import { default as hO } from "./icons/Symbol/Fill/MailBadge.js";
import { default as OO } from "./icons/Symbol/Fill/MailCheck.js";
import { default as gO } from "./icons/Symbol/Fill/MailMinusCircle.js";
import { default as kO } from "./icons/Symbol/Fill/MailOpen.js";
import { default as PO } from "./icons/Symbol/Fill/MailPaperclip.js";
import { default as GO } from "./icons/Symbol/Fill/Mail.js";
import { default as LO } from "./icons/Symbol/Fill/MapMarkerClose.js";
import { default as wO } from "./icons/Symbol/Fill/MapMarkerDrop.js";
import { default as yO } from "./icons/Symbol/Fill/MapMarkerOff.js";
import { default as EO } from "./icons/Symbol/Fill/MapMarkerPlus.js";
import { default as VO } from "./icons/Symbol/Fill/MapMarker.js";
import { default as JO } from "./icons/Symbol/Fill/Map.js";
import { default as zO } from "./icons/Symbol/Fill/Melange.js";
import { default as qO } from "./icons/Symbol/Fill/MenuDownCircle.js";
import { default as ZO } from "./icons/Symbol/Fill/MenuLeftCircle.js";
import { default as jO } from "./icons/Symbol/Fill/MenuRightCircle.js";
import { default as XO } from "./icons/Symbol/Fill/MenuUpCircle.js";
import { default as $O } from "./icons/Symbol/Fill/MessageAccount.js";
import { default as eA } from "./icons/Symbol/Fill/MessageAlt.js";
import { default as tA } from "./icons/Symbol/Fill/MessageCheck.js";
import { default as lA } from "./icons/Symbol/Fill/Message.js";
import { default as nA } from "./icons/Symbol/Fill/MicrophoneOff.js";
import { default as cA } from "./icons/Symbol/Fill/Microphone.js";
import { default as sA } from "./icons/Symbol/Fill/Microscope.js";
import { default as mA } from "./icons/Symbol/Fill/MinusCircle.js";
import { default as xA } from "./icons/Symbol/Fill/MotorBike.js";
import { default as FA } from "./icons/Symbol/Fill/NearMeOff.js";
import { default as hA } from "./icons/Symbol/Fill/NearMe.js";
import { default as OA } from "./icons/Symbol/Fill/Newspaper.js";
import { default as gA } from "./icons/Symbol/Fill/NoteCalendar.js";
import { default as kA } from "./icons/Symbol/Fill/NoteText.js";
import { default as PA } from "./icons/Symbol/Fill/Note.js";
import { default as GA } from "./icons/Symbol/Fill/PagesPlus.js";
import { default as LA } from "./icons/Symbol/Fill/Pages.js";
import { default as wA } from "./icons/Symbol/Fill/Palette.js";
import { default as yA } from "./icons/Symbol/Fill/PaperclipFile.js";
import { default as EA } from "./icons/Symbol/Fill/Pause.js";
import { default as VA } from "./icons/Symbol/Fill/PencilRuler.js";
import { default as JA } from "./icons/Symbol/Fill/PersonalPlay.js";
import { default as zA } from "./icons/Symbol/Fill/PhoneInTalk.js";
import { default as qA } from "./icons/Symbol/Fill/Phone.js";
import { default as ZA } from "./icons/Symbol/Fill/Pin.js";
import { default as jA } from "./icons/Symbol/Fill/PlayCircle.js";
import { default as XA } from "./icons/Symbol/Fill/Play.js";
import { default as $A } from "./icons/Symbol/Fill/PlusAlarm.js";
import { default as eg } from "./icons/Symbol/Fill/PlusBell.js";
import { default as tg } from "./icons/Symbol/Fill/PlusCircle.js";
import { default as lg } from "./icons/Symbol/Fill/PlusFile.js";
import { default as ng } from "./icons/Symbol/Fill/PlusMapMarker.js";
import { default as cg } from "./icons/Symbol/Fill/PlusSquare.js";
import { default as sg } from "./icons/Symbol/Fill/PlusVideo.js";
import { default as mg } from "./icons/Symbol/Fill/PrinterOff.js";
import { default as xg } from "./icons/Symbol/Fill/Printer.js";
import { default as Fg } from "./icons/Symbol/Fill/Publish.js";
import { default as hg } from "./icons/Symbol/Fill/QuestionMapMarker.js";
import { default as Og } from "./icons/Symbol/Fill/RadioBlankMultiple.js";
import { default as gg } from "./icons/Symbol/Fill/RadioBlank.js";
import { default as kg } from "./icons/Symbol/Fill/RadioMarkedMultiple.js";
import { default as Pg } from "./icons/Symbol/Fill/RadioMarked.js";
import { default as Gg } from "./icons/Symbol/Fill/Radio.js";
import { default as Lg } from "./icons/Symbol/Fill/RocketLaunch.js";
import { default as wg } from "./icons/Symbol/Fill/Rocket.js";
import { default as yg } from "./icons/Symbol/Fill/Rotate90DegreeCcw.js";
import { default as Eg } from "./icons/Symbol/Fill/Rotate90DegreeCw.js";
import { default as Vg } from "./icons/Symbol/Fill/Scanner.js";
import { default as Jg } from "./icons/Symbol/Fill/ScatterPlot.js";
import { default as zg } from "./icons/Symbol/Fill/SchemaHorizontal.js";
import { default as qg } from "./icons/Symbol/Fill/SchemaVertical.js";
import { default as Zg } from "./icons/Symbol/Fill/ScriptText.js";
import { default as jg } from "./icons/Symbol/Fill/Script.js";
import { default as Xg } from "./icons/Symbol/Fill/SendClock.js";
import { default as $g } from "./icons/Symbol/Fill/Send.js";
import { default as eS } from "./icons/Symbol/Fill/Shape.js";
import { default as tS } from "./icons/Symbol/Fill/Share.js";
import { default as lS } from "./icons/Symbol/Fill/ShieldAccount.js";
import { default as nS } from "./icons/Symbol/Fill/ShoppingBag.js";
import { default as cS } from "./icons/Symbol/Fill/Soccerball.js";
import { default as sS } from "./icons/Symbol/Fill/SquircleSm.js";
import { default as mS } from "./icons/Symbol/Fill/Star.js";
import { default as xS } from "./icons/Symbol/Fill/StickyNoteText.js";
import { default as FS } from "./icons/Symbol/Fill/StickyNote.js";
import { default as hS } from "./icons/Symbol/Fill/Stop.js";
import { default as OS } from "./icons/Symbol/Fill/SupervisedUserCircleOff.js";
import { default as gS } from "./icons/Symbol/Fill/SupervisedUserCircle.js";
import { default as kS } from "./icons/Symbol/Fill/SupervisedUser.js";
import { default as PS } from "./icons/Symbol/Fill/Tag.js";
import { default as GS } from "./icons/Symbol/Fill/Target.js";
import { default as LS } from "./icons/Symbol/Fill/Tennis.js";
import { default as wS } from "./icons/Symbol/Fill/TestTubeSearch.js";
import { default as yS } from "./icons/Symbol/Fill/TestTube.js";
import { default as ES } from "./icons/Symbol/Fill/TextBookmark.js";
import { default as VS } from "./icons/Symbol/Fill/TextMessageAlt.js";
import { default as JS } from "./icons/Symbol/Fill/TextMessage.js";
import { default as zS } from "./icons/Symbol/Fill/TextSquareMultiple.js";
import { default as qS } from "./icons/Symbol/Fill/TextSquare.js";
import { default as ZS } from "./icons/Symbol/Fill/ThumbDown.js";
import { default as jS } from "./icons/Symbol/Fill/ThumbUpDown.js";
import { default as XS } from "./icons/Symbol/Fill/ThumbUp.js";
import { default as $S } from "./icons/Symbol/Fill/TimerOff.js";
import { default as ek } from "./icons/Symbol/Fill/Timer.js";
import { default as tk } from "./icons/Symbol/Fill/ToggleOff.js";
import { default as lk } from "./icons/Symbol/Fill/ToggleOn.js";
import { default as nk } from "./icons/Symbol/Fill/Tooltip.js";
import { default as ck } from "./icons/Symbol/Fill/TrainCar.js";
import { default as sk } from "./icons/Symbol/Fill/Train.js";
import { default as mk } from "./icons/Symbol/Fill/Tram.js";
import { default as xk } from "./icons/Symbol/Fill/TuneAltVertical.js";
import { default as Fk } from "./icons/Symbol/Fill/TuneAlt.js";
import { default as hk } from "./icons/Symbol/Fill/Unarchive.js";
import { default as Ok } from "./icons/Symbol/Fill/VideoOff.js";
import { default as gk } from "./icons/Symbol/Fill/Video.js";
import { default as kk } from "./icons/Symbol/Fill/ViewDashboardAlt.js";
import { default as Pk } from "./icons/Symbol/Fill/ViewDashboardSpace.js";
import { default as Gk } from "./icons/Symbol/Fill/ViewDashboard.js";
import { default as Lk } from "./icons/Symbol/Fill/ViewGridModule.js";
import { default as wk } from "./icons/Symbol/Fill/ViewGridPlus.js";
import { default as yk } from "./icons/Symbol/Fill/ViewGrid.js";
import { default as Ek } from "./icons/Symbol/Fill/VolumeHigh.js";
import { default as Vk } from "./icons/Symbol/Fill/VolumeLow.js";
import { default as Jk } from "./icons/Symbol/Fill/VolumeMute.js";
import { default as zk } from "./icons/Symbol/Fill/VolumeOff.js";
import { default as qk } from "./icons/Symbol/Fill/Wallet.js";
import { default as Zk } from "./icons/Symbol/Fill/WeatherNight.js";
import { default as jk } from "./icons/Symbol/Fill/WeatherSunny.js";
import { default as Xk } from "./icons/Symbol/Fill/Workspace.js";
import { default as $k } from "./icons/Symbol/Outline/AccountCircle.js";
import { default as eb } from "./icons/Symbol/Outline/AccountClipboard.js";
import { default as tb } from "./icons/Symbol/Outline/AccountCog.js";
import { default as lb } from "./icons/Symbol/Outline/AccountMinus.js";
import { default as nb } from "./icons/Symbol/Outline/AccountMultipleMinus.js";
import { default as cb } from "./icons/Symbol/Outline/AccountMultipleOff.js";
import { default as sb } from "./icons/Symbol/Outline/AccountMultiplePlus.js";
import { default as mb } from "./icons/Symbol/Outline/AccountMultiple.js";
import { default as xb } from "./icons/Symbol/Outline/AccountPlus.js";
import { default as Fb } from "./icons/Symbol/Outline/AccountSquare.js";
import { default as hb } from "./icons/Symbol/Outline/Account.js";
import { default as Ob } from "./icons/Symbol/Outline/AlarmOff.js";
import { default as gb } from "./icons/Symbol/Outline/Alarm.js";
import { default as kb } from "./icons/Symbol/Outline/AlertBell.js";
import { default as Pb } from "./icons/Symbol/Outline/AlertCircle.js";
import { default as Gb } from "./icons/Symbol/Outline/AlertDecagram.js";
import { default as Lb } from "./icons/Symbol/Outline/AlertMapMarker.js";
import { default as wb } from "./icons/Symbol/Outline/AlertOctagon.js";
import { default as yb } from "./icons/Symbol/Outline/AlertRhombus.js";
import { default as Eb } from "./icons/Symbol/Outline/AlertShield.js";
import { default as Vb } from "./icons/Symbol/Outline/AlertTriangle.js";
import { default as Jb } from "./icons/Symbol/Outline/ArView.js";
import { default as zb } from "./icons/Symbol/Outline/Archive.js";
import { default as qb } from "./icons/Symbol/Outline/Article.js";
import { default as Zb } from "./icons/Symbol/Outline/Backspace.js";
import { default as jb } from "./icons/Symbol/Outline/BadgeLg.js";
import { default as Xb } from "./icons/Symbol/Outline/BadgeMd.js";
import { default as $b } from "./icons/Symbol/Outline/BadgeSm.js";
import { default as eP } from "./icons/Symbol/Outline/Bank.js";
import { default as tP } from "./icons/Symbol/Outline/Basketball.js";
import { default as lP } from "./icons/Symbol/Outline/BellActive.js";
import { default as nP } from "./icons/Symbol/Outline/BellCircle.js";
import { default as cP } from "./icons/Symbol/Outline/BellClock.js";
import { default as sP } from "./icons/Symbol/Outline/BellEdit.js";
import { default as mP } from "./icons/Symbol/Outline/BellOff.js";
import { default as xP } from "./icons/Symbol/Outline/BellPlus.js";
import { default as FP } from "./icons/Symbol/Outline/Bell.js";
import { default as hP } from "./icons/Symbol/Outline/Bolt.js";
import { default as OP } from "./icons/Symbol/Outline/BookOpenPage.js";
import { default as gP } from "./icons/Symbol/Outline/BookOpenText.js";
import { default as kP } from "./icons/Symbol/Outline/BookOpen.js";
import { default as PP } from "./icons/Symbol/Outline/BookmarkMultiple.js";
import { default as GP } from "./icons/Symbol/Outline/BookmarkOff.js";
import { default as LP } from "./icons/Symbol/Outline/BookmarkPlus.js";
import { default as wP } from "./icons/Symbol/Outline/Bookmark.js";
import { default as yP } from "./icons/Symbol/Outline/BorderRadius.js";
import { default as EP } from "./icons/Symbol/Outline/BriefcaseAlt.js";
import { default as VP } from "./icons/Symbol/Outline/Brightness4.js";
import { default as JP } from "./icons/Symbol/Outline/Brightness5.js";
import { default as zP } from "./icons/Symbol/Outline/Brightness6.js";
import { default as qP } from "./icons/Symbol/Outline/Brightness7.js";
import { default as ZP } from "./icons/Symbol/Outline/Brush.js";
import { default as jP } from "./icons/Symbol/Outline/Bug.js";
import { default as XP } from "./icons/Symbol/Outline/BullhornHigh.js";
import { default as $P } from "./icons/Symbol/Outline/BullhornOff.js";
import { default as eD } from "./icons/Symbol/Outline/Bullhorn.js";
import { default as tD } from "./icons/Symbol/Outline/Bus.js";
import { default as lD } from "./icons/Symbol/Outline/Cake.js";
import { default as nD } from "./icons/Symbol/Outline/CalendarBlank.js";
import { default as cD } from "./icons/Symbol/Outline/CalendarClock.js";
import { default as sD } from "./icons/Symbol/Outline/CalendarClose.js";
import { default as mD } from "./icons/Symbol/Outline/CalendarEdit.js";
import { default as xD } from "./icons/Symbol/Outline/CalendarEvent.js";
import { default as FD } from "./icons/Symbol/Outline/CalendarMonth.js";
import { default as hD } from "./icons/Symbol/Outline/CalendarPlus.js";
import { default as OD } from "./icons/Symbol/Outline/CalendarScheduled.js";
import { default as gD } from "./icons/Symbol/Outline/CalendarToday.js";
import { default as kD } from "./icons/Symbol/Outline/CalendarWeek.js";
import { default as PD } from "./icons/Symbol/Outline/CameraPlus.js";
import { default as GD } from "./icons/Symbol/Outline/Camera.js";
import { default as LD } from "./icons/Symbol/Outline/Car.js";
import { default as wD } from "./icons/Symbol/Outline/Cart.js";
import { default as yD } from "./icons/Symbol/Outline/CellphoneAndroid.js";
import { default as ED } from "./icons/Symbol/Outline/CellphoneCog.js";
import { default as VD } from "./icons/Symbol/Outline/CellphoneIos.js";
import { default as JD } from "./icons/Symbol/Outline/Cellphone.js";
import { default as zD } from "./icons/Symbol/Outline/ChartArea.js";
import { default as qD } from "./icons/Symbol/Outline/ChartBarSquare.js";
import { default as ZD } from "./icons/Symbol/Outline/ChartBubble.js";
import { default as jD } from "./icons/Symbol/Outline/CheckAlarm.js";
import { default as XD } from "./icons/Symbol/Outline/CheckCalendar.js";
import { default as $D } from "./icons/Symbol/Outline/CheckCircle.js";
import { default as eG } from "./icons/Symbol/Outline/CheckCloud.js";
import { default as tG } from "./icons/Symbol/Outline/CheckDecagram.js";
import { default as lG } from "./icons/Symbol/Outline/CheckMapMarker.js";
import { default as nG } from "./icons/Symbol/Outline/CheckShield.js";
import { default as cG } from "./icons/Symbol/Outline/CheckboxBlankMultiple.js";
import { default as sG } from "./icons/Symbol/Outline/CheckboxBlank.js";
import { default as mG } from "./icons/Symbol/Outline/CheckboxMarkedMultiple.js";
import { default as xG } from "./icons/Symbol/Outline/CheckboxMarked.js";
import { default as FG } from "./icons/Symbol/Outline/ChevronDownCircle.js";
import { default as hG } from "./icons/Symbol/Outline/ChevronLeftCircle.js";
import { default as OG } from "./icons/Symbol/Outline/ChevronRightCircle.js";
import { default as gG } from "./icons/Symbol/Outline/ChevronUpCircle.js";
import { default as kG } from "./icons/Symbol/Outline/Chrome.js";
import { default as PG } from "./icons/Symbol/Outline/CircleOff.js";
import { default as GG } from "./icons/Symbol/Outline/CircleSlice1.js";
import { default as LG } from "./icons/Symbol/Outline/CircleSlice2.js";
import { default as wG } from "./icons/Symbol/Outline/CircleSlice3.js";
import { default as yG } from "./icons/Symbol/Outline/CircleSlice4.js";
import { default as EG } from "./icons/Symbol/Outline/CircleSlice5.js";
import { default as VG } from "./icons/Symbol/Outline/CircleSlice6.js";
import { default as JG } from "./icons/Symbol/Outline/CircleSlice7.js";
import { default as zG } from "./icons/Symbol/Outline/CircleSlice8.js";
import { default as qG } from "./icons/Symbol/Outline/Circle.js";
import { default as ZG } from "./icons/Symbol/Outline/ClockMultiple.js";
import { default as jG } from "./icons/Symbol/Outline/Clock.js";
import { default as XG } from "./icons/Symbol/Outline/CloseCalendar.js";
import { default as $G } from "./icons/Symbol/Outline/CloseCircle.js";
import { default as eB } from "./icons/Symbol/Outline/CloudAlt.js";
import { default as tB } from "./icons/Symbol/Outline/CloudDownload.js";
import { default as lB } from "./icons/Symbol/Outline/CloudOff.js";
import { default as nB } from "./icons/Symbol/Outline/CloudUpload.js";
import { default as cB } from "./icons/Symbol/Outline/Cloud.js";
import { default as sB } from "./icons/Symbol/Outline/CodeSquare.js";
import { default as mB } from "./icons/Symbol/Outline/CogMultiple.js";
import { default as xB } from "./icons/Symbol/Outline/Cog.js";
import { default as FB } from "./icons/Symbol/Outline/CompassOff.js";
import { default as hB } from "./icons/Symbol/Outline/Compass.js";
import { default as OB } from "./icons/Symbol/Outline/ContentCopyIos.js";
import { default as gB } from "./icons/Symbol/Outline/ContentCopy.js";
import { default as kB } from "./icons/Symbol/Outline/ContentCut.js";
import { default as PB } from "./icons/Symbol/Outline/ContentSave.js";
import { default as GB } from "./icons/Symbol/Outline/CrosshairsGps.js";
import { default as LB } from "./icons/Symbol/Outline/CurrencyDollarCircle.js";
import { default as wB } from "./icons/Symbol/Outline/DataTableStar.js";
import { default as yB } from "./icons/Symbol/Outline/DataTable.js";
import { default as EB } from "./icons/Symbol/Outline/Database.js";
import { default as VB } from "./icons/Symbol/Outline/Delete.js";
import { default as JB } from "./icons/Symbol/Outline/DevicesOff.js";
import { default as zB } from "./icons/Symbol/Outline/Devices.js";
import { default as qB } from "./icons/Symbol/Outline/DoorMeetingRoom.js";
import { default as ZB } from "./icons/Symbol/Outline/DotsMessage.js";
import { default as jB } from "./icons/Symbol/Outline/DropOff.js";
import { default as XB } from "./icons/Symbol/Outline/Drop.js";
import { default as $B } from "./icons/Symbol/Outline/Edit.js";
import { default as eL } from "./icons/Symbol/Outline/EmojiOff.js";
import { default as tL } from "./icons/Symbol/Outline/EmoticonAngry.js";
import { default as lL } from "./icons/Symbol/Outline/EmoticonClose.js";
import { default as nL } from "./icons/Symbol/Outline/EmoticonDead.js";
import { default as cL } from "./icons/Symbol/Outline/EmoticonExcited.js";
import { default as sL } from "./icons/Symbol/Outline/EmoticonFrown.js";
import { default as mL } from "./icons/Symbol/Outline/EmoticonHappy.js";
import { default as xL } from "./icons/Symbol/Outline/EmoticonNeutral.js";
import { default as FL } from "./icons/Symbol/Outline/EmoticonPlus.js";
import { default as hL } from "./icons/Symbol/Outline/EmoticonSad.js";
import { default as OL } from "./icons/Symbol/Outline/Emoticon.js";
import { default as gL } from "./icons/Symbol/Outline/ErlenmeyerFlaskFull.js";
import { default as kL } from "./icons/Symbol/Outline/ErlenmeyerFlask.js";
import { default as PL } from "./icons/Symbol/Outline/EyeOff.js";
import { default as GL } from "./icons/Symbol/Outline/Eye.js";
import { default as LL } from "./icons/Symbol/Outline/FileClose.js";
import { default as wL } from "./icons/Symbol/Outline/FileDocument.js";
import { default as yL } from "./icons/Symbol/Outline/FileEye.js";
import { default as EL } from "./icons/Symbol/Outline/FileMultiple.js";
import { default as VL } from "./icons/Symbol/Outline/FilePlus.js";
import { default as JL } from "./icons/Symbol/Outline/FileReply.js";
import { default as zL } from "./icons/Symbol/Outline/FileSearch.js";
import { default as qL } from "./icons/Symbol/Outline/FileUpload.js";
import { default as ZL } from "./icons/Symbol/Outline/File.js";
import { default as jL } from "./icons/Symbol/Outline/FilterOff.js";
import { default as XL } from "./icons/Symbol/Outline/Filter.js";
import { default as $L } from "./icons/Symbol/Outline/Flag.js";
import { default as eT } from "./icons/Symbol/Outline/Flower.js";
import { default as tT } from "./icons/Symbol/Outline/FolderClock.js";
import { default as lT } from "./icons/Symbol/Outline/FolderMultiple.js";
import { default as nT } from "./icons/Symbol/Outline/FolderOff.js";
import { default as cT } from "./icons/Symbol/Outline/FolderUpload.js";
import { default as sT } from "./icons/Symbol/Outline/Folder.js";
import { default as mT } from "./icons/Symbol/Outline/FoodOff.js";
import { default as xT } from "./icons/Symbol/Outline/Food.js";
import { default as FT } from "./icons/Symbol/Outline/Football.js";
import { default as hT } from "./icons/Symbol/Outline/FormatColorHighlight.js";
import { default as OT } from "./icons/Symbol/Outline/FormatImageCaption.js";
import { default as gT } from "./icons/Symbol/Outline/FormatImageCenter.js";
import { default as kT } from "./icons/Symbol/Outline/FormatImageCoverAlt.js";
import { default as PT } from "./icons/Symbol/Outline/FormatImageCover.js";
import { default as GT } from "./icons/Symbol/Outline/FormatImageLeftAlt.js";
import { default as LT } from "./icons/Symbol/Outline/FormatImageLeft.js";
import { default as wT } from "./icons/Symbol/Outline/FormatImageOverflow.js";
import { default as yT } from "./icons/Symbol/Outline/FormatImageRightAlt.js";
import { default as ET } from "./icons/Symbol/Outline/FormatImageRight.js";
import { default as VT } from "./icons/Symbol/Outline/FormatQuoteClose.js";
import { default as JT } from "./icons/Symbol/Outline/FormatQuoteOpen.js";
import { default as zT } from "./icons/Symbol/Outline/Forum.js";
import { default as qT } from "./icons/Symbol/Outline/GaugeHigh.js";
import { default as ZT } from "./icons/Symbol/Outline/GaugeLow.js";
import { default as jT } from "./icons/Symbol/Outline/GetApp.js";
import { default as XT } from "./icons/Symbol/Outline/GifSquare.js";
import { default as $T } from "./icons/Symbol/Outline/GoogleDrivePlus.js";
import { default as ew } from "./icons/Symbol/Outline/GoogleDrive.js";
import { default as tw } from "./icons/Symbol/Outline/Group.js";
import { default as lw } from "./icons/Symbol/Outline/HammerScrewdriver.js";
import { default as nw } from "./icons/Symbol/Outline/HashtagSquare.js";
import { default as cw } from "./icons/Symbol/Outline/Headphones.js";
import { default as sw } from "./icons/Symbol/Outline/HeadsetOff.js";
import { default as mw } from "./icons/Symbol/Outline/Headset.js";
import { default as xw } from "./icons/Symbol/Outline/Heart.js";
import { default as Fw } from "./icons/Symbol/Outline/HelpCircle.js";
import { default as hw } from "./icons/Symbol/Outline/HelpTooltip.js";
import { default as Ow } from "./icons/Symbol/Outline/HighlightMarker.js";
import { default as gw } from "./icons/Symbol/Outline/Home.js";
import { default as kw } from "./icons/Symbol/Outline/ImageAlt.js";
import { default as Pw } from "./icons/Symbol/Outline/ImageBroken.js";
import { default as Gw } from "./icons/Symbol/Outline/ImageFilterCenterFocusStrong.js";
import { default as Lw } from "./icons/Symbol/Outline/ImageFilterCenterFocusWeak.js";
import { default as ww } from "./icons/Symbol/Outline/ImageMultiple.js";
import { default as yw } from "./icons/Symbol/Outline/ImagePlusAlt.js";
import { default as Ew } from "./icons/Symbol/Outline/ImagePlus.js";
import { default as Vw } from "./icons/Symbol/Outline/ImageStar.js";
import { default as Jw } from "./icons/Symbol/Outline/Image.js";
import { default as zw } from "./icons/Symbol/Outline/InboxMultiple.js";
import { default as qw } from "./icons/Symbol/Outline/Inbox.js";
import { default as Zw } from "./icons/Symbol/Outline/InformationCircle.js";
import { default as jw } from "./icons/Symbol/Outline/InformationShield.js";
import { default as Xw } from "./icons/Symbol/Outline/Keyboard.js";
import { default as $w } from "./icons/Symbol/Outline/LabelAlt.js";
import { default as eR } from "./icons/Symbol/Outline/LabelOff.js";
import { default as tR } from "./icons/Symbol/Outline/Label.js";
import { default as lR } from "./icons/Symbol/Outline/LaptopMac.js";
import { default as nR } from "./icons/Symbol/Outline/LayerMultipleMinus.js";
import { default as cR } from "./icons/Symbol/Outline/LayerMultipleOff.js";
import { default as sR } from "./icons/Symbol/Outline/LayerMultiplePlus.js";
import { default as mR } from "./icons/Symbol/Outline/LayerMultiple.js";
import { default as xR } from "./icons/Symbol/Outline/Leaf.js";
import { default as FR } from "./icons/Symbol/Outline/LightbulbAlt.js";
import { default as hR } from "./icons/Symbol/Outline/LightbulbMelangeAlt.js";
import { default as OR } from "./icons/Symbol/Outline/LightbulbMelange.js";
import { default as gR } from "./icons/Symbol/Outline/Lightbulb.js";
import { default as kR } from "./icons/Symbol/Outline/LockOpen.js";
import { default as PR } from "./icons/Symbol/Outline/Lock.js";
import { default as GR } from "./icons/Symbol/Outline/MagicWand.js";
import { default as LR } from "./icons/Symbol/Outline/MailArrowRight.js";
import { default as wR } from "./icons/Symbol/Outline/MailBadge.js";
import { default as yR } from "./icons/Symbol/Outline/MailCheck.js";
import { default as ER } from "./icons/Symbol/Outline/MailMinusCircle.js";
import { default as VR } from "./icons/Symbol/Outline/MailOpen.js";
import { default as JR } from "./icons/Symbol/Outline/MailPaperclip.js";
import { default as zR } from "./icons/Symbol/Outline/Mail.js";
import { default as qR } from "./icons/Symbol/Outline/MapMarkerClose.js";
import { default as ZR } from "./icons/Symbol/Outline/MapMarkerDrop.js";
import { default as jR } from "./icons/Symbol/Outline/MapMarkerOff.js";
import { default as XR } from "./icons/Symbol/Outline/MapMarkerPlus.js";
import { default as $R } from "./icons/Symbol/Outline/MapMarker.js";
import { default as ey } from "./icons/Symbol/Outline/Map.js";
import { default as ty } from "./icons/Symbol/Outline/Math.js";
import { default as ly } from "./icons/Symbol/Outline/Melange.js";
import { default as ny } from "./icons/Symbol/Outline/MenuDownCircle.js";
import { default as cy } from "./icons/Symbol/Outline/MenuLeftCircle.js";
import { default as sy } from "./icons/Symbol/Outline/MenuRightCircle.js";
import { default as my } from "./icons/Symbol/Outline/MenuUpCircle.js";
import { default as xy } from "./icons/Symbol/Outline/MessageAccount.js";
import { default as Fy } from "./icons/Symbol/Outline/MessageAlt.js";
import { default as hy } from "./icons/Symbol/Outline/MessageCheck.js";
import { default as Oy } from "./icons/Symbol/Outline/Message.js";
import { default as gy } from "./icons/Symbol/Outline/MicrophoneOff.js";
import { default as ky } from "./icons/Symbol/Outline/Microphone.js";
import { default as Py } from "./icons/Symbol/Outline/Microscope.js";
import { default as Gy } from "./icons/Symbol/Outline/MinusCircle.js";
import { default as Ly } from "./icons/Symbol/Outline/MotorBike.js";
import { default as wy } from "./icons/Symbol/Outline/NearMeOff.js";
import { default as yy } from "./icons/Symbol/Outline/NearMe.js";
import { default as Ey } from "./icons/Symbol/Outline/Newspaper.js";
import { default as Vy } from "./icons/Symbol/Outline/NoteCalendar.js";
import { default as Jy } from "./icons/Symbol/Outline/NoteText.js";
import { default as zy } from "./icons/Symbol/Outline/Note.js";
import { default as qy } from "./icons/Symbol/Outline/PagesPlus.js";
import { default as Zy } from "./icons/Symbol/Outline/Pages.js";
import { default as jy } from "./icons/Symbol/Outline/Palette.js";
import { default as Xy } from "./icons/Symbol/Outline/PaperclipFile.js";
import { default as $y } from "./icons/Symbol/Outline/Pause.js";
import { default as ev } from "./icons/Symbol/Outline/PencilRuler.js";
import { default as tv } from "./icons/Symbol/Outline/PersonalPlay.js";
import { default as lv } from "./icons/Symbol/Outline/PhoneInTalk.js";
import { default as nv } from "./icons/Symbol/Outline/Phone.js";
import { default as cv } from "./icons/Symbol/Outline/Pin.js";
import { default as sv } from "./icons/Symbol/Outline/PlayCircle.js";
import { default as mv } from "./icons/Symbol/Outline/Play.js";
import { default as xv } from "./icons/Symbol/Outline/PlusAlarm.js";
import { default as Fv } from "./icons/Symbol/Outline/PlusBell.js";
import { default as hv } from "./icons/Symbol/Outline/PlusCircle.js";
import { default as Ov } from "./icons/Symbol/Outline/PlusFile.js";
import { default as gv } from "./icons/Symbol/Outline/PlusMapMarker.js";
import { default as kv } from "./icons/Symbol/Outline/PlusSquare.js";
import { default as Pv } from "./icons/Symbol/Outline/PlusVideo.js";
import { default as Gv } from "./icons/Symbol/Outline/PrinterOff.js";
import { default as Lv } from "./icons/Symbol/Outline/Printer.js";
import { default as wv } from "./icons/Symbol/Outline/Publish.js";
import { default as yv } from "./icons/Symbol/Outline/QuestionMapMarker.js";
import { default as Ev } from "./icons/Symbol/Outline/RadioBlankMultiple.js";
import { default as Vv } from "./icons/Symbol/Outline/RadioBlank.js";
import { default as Jv } from "./icons/Symbol/Outline/RadioMarkedMultiple.js";
import { default as zv } from "./icons/Symbol/Outline/RadioMarked.js";
import { default as qv } from "./icons/Symbol/Outline/Radio.js";
import { default as Zv } from "./icons/Symbol/Outline/RocketLaunch.js";
import { default as jv } from "./icons/Symbol/Outline/Rocket.js";
import { default as Xv } from "./icons/Symbol/Outline/Rotate90DegreeCcw.js";
import { default as $v } from "./icons/Symbol/Outline/Rotate90DegreeCw.js";
import { default as eE } from "./icons/Symbol/Outline/Scanner.js";
import { default as tE } from "./icons/Symbol/Outline/ScatterPlot.js";
import { default as lE } from "./icons/Symbol/Outline/SchemaHorizontal.js";
import { default as nE } from "./icons/Symbol/Outline/SchemaVertical.js";
import { default as cE } from "./icons/Symbol/Outline/ScriptText.js";
import { default as sE } from "./icons/Symbol/Outline/Script.js";
import { default as mE } from "./icons/Symbol/Outline/SendClock.js";
import { default as xE } from "./icons/Symbol/Outline/Send.js";
import { default as FE } from "./icons/Symbol/Outline/Shape.js";
import { default as hE } from "./icons/Symbol/Outline/Share.js";
import { default as OE } from "./icons/Symbol/Outline/ShieldAccount.js";
import { default as gE } from "./icons/Symbol/Outline/ShoppingBag.js";
import { default as kE } from "./icons/Symbol/Outline/Soccerball.js";
import { default as PE } from "./icons/Symbol/Outline/SquircleSm.js";
import { default as GE } from "./icons/Symbol/Outline/Star.js";
import { default as LE } from "./icons/Symbol/Outline/StickyNoteText.js";
import { default as wE } from "./icons/Symbol/Outline/StickyNote.js";
import { default as yE } from "./icons/Symbol/Outline/Stop.js";
import { default as EE } from "./icons/Symbol/Outline/SupervisedUserCircleOff.js";
import { default as VE } from "./icons/Symbol/Outline/SupervisedUserCircle.js";
import { default as JE } from "./icons/Symbol/Outline/SupervisedUser.js";
import { default as zE } from "./icons/Symbol/Outline/Tag.js";
import { default as qE } from "./icons/Symbol/Outline/Target.js";
import { default as ZE } from "./icons/Symbol/Outline/Tennis.js";
import { default as jE } from "./icons/Symbol/Outline/TestTubeSearch.js";
import { default as XE } from "./icons/Symbol/Outline/TestTube.js";
import { default as $E } from "./icons/Symbol/Outline/TextBookmark.js";
import { default as eH } from "./icons/Symbol/Outline/TextMessageAlt.js";
import { default as tH } from "./icons/Symbol/Outline/TextMessage.js";
import { default as lH } from "./icons/Symbol/Outline/TextSquareMultiple.js";
import { default as nH } from "./icons/Symbol/Outline/TextSquare.js";
import { default as cH } from "./icons/Symbol/Outline/ThumbDown.js";
import { default as sH } from "./icons/Symbol/Outline/ThumbUpDown.js";
import { default as mH } from "./icons/Symbol/Outline/ThumbUp.js";
import { default as xH } from "./icons/Symbol/Outline/TimerOff.js";
import { default as FH } from "./icons/Symbol/Outline/Timer.js";
import { default as hH } from "./icons/Symbol/Outline/ToggleOff.js";
import { default as OH } from "./icons/Symbol/Outline/ToggleOn.js";
import { default as gH } from "./icons/Symbol/Outline/Tooltip.js";
import { default as kH } from "./icons/Symbol/Outline/TrainCar.js";
import { default as PH } from "./icons/Symbol/Outline/Train.js";
import { default as GH } from "./icons/Symbol/Outline/Tram.js";
import { default as LH } from "./icons/Symbol/Outline/TuneAltVertical.js";
import { default as wH } from "./icons/Symbol/Outline/TuneAlt.js";
import { default as yH } from "./icons/Symbol/Outline/Unarchive.js";
import { default as EH } from "./icons/Symbol/Outline/VideoOff.js";
import { default as VH } from "./icons/Symbol/Outline/Video.js";
import { default as JH } from "./icons/Symbol/Outline/ViewDashboardAlt.js";
import { default as zH } from "./icons/Symbol/Outline/ViewDashboardSpace.js";
import { default as qH } from "./icons/Symbol/Outline/ViewDashboard.js";
import { default as ZH } from "./icons/Symbol/Outline/ViewGridModule.js";
import { default as jH } from "./icons/Symbol/Outline/ViewGridPlus.js";
import { default as XH } from "./icons/Symbol/Outline/ViewGrid.js";
import { default as $H } from "./icons/Symbol/Outline/VolumeHigh.js";
import { default as eV } from "./icons/Symbol/Outline/VolumeLow.js";
import { default as tV } from "./icons/Symbol/Outline/VolumeMute.js";
import { default as lV } from "./icons/Symbol/Outline/VolumeOff.js";
import { default as nV } from "./icons/Symbol/Outline/Wallet.js";
import { default as cV } from "./icons/Symbol/Outline/WeatherNight.js";
import { default as sV } from "./icons/Symbol/Outline/WeatherSunny.js";
import { default as mV } from "./icons/Symbol/Outline/Workspace.js";
import { default as xV } from "./icons/Symbol/Common/AccessibilityAlt.js";
import { default as FV } from "./icons/Symbol/Common/Accessibility.js";
import { default as hV } from "./icons/Symbol/Common/AccessibleForward.js";
import { default as OV } from "./icons/Symbol/Common/Accessible.js";
import { default as gV } from "./icons/Symbol/Common/AlertSync.js";
import { default as kV } from "./icons/Symbol/Common/Alert.js";
import { default as PV } from "./icons/Symbol/Common/AlignHorizontalCenter.js";
import { default as GV } from "./icons/Symbol/Common/AlignHorizontalDistribute.js";
import { default as LV } from "./icons/Symbol/Common/AlignHorizontalLeft.js";
import { default as wV } from "./icons/Symbol/Common/AlignHorizontalRight.js";
import { default as yV } from "./icons/Symbol/Common/AlignVerticalBottom.js";
import { default as EV } from "./icons/Symbol/Common/AlignVerticalDistribute.js";
import { default as VV } from "./icons/Symbol/Common/AlignVerticalTop.js";
import { default as JV } from "./icons/Symbol/Common/Android.js";
import { default as zV } from "./icons/Symbol/Common/Apartment.js";
import { default as qV } from "./icons/Symbol/Common/ArrowCollapseAll.js";
import { default as ZV } from "./icons/Symbol/Common/ArrowCollapse.js";
import { default as jV } from "./icons/Symbol/Common/ArrowDown.js";
import { default as XV } from "./icons/Symbol/Common/ArrowExpandAll.js";
import { default as $V } from "./icons/Symbol/Common/ArrowExpand.js";
import { default as eU } from "./icons/Symbol/Common/ArrowLeft.js";
import { default as tU } from "./icons/Symbol/Common/ArrowRight.js";
import { default as lU } from "./icons/Symbol/Common/ArrowUp.js";
import { default as nU } from "./icons/Symbol/Common/Asterisk.js";
import { default as cU } from "./icons/Symbol/Common/At.js";
import { default as sU } from "./icons/Symbol/Common/Autorenew.js";
import { default as mU } from "./icons/Symbol/Common/AvTimer.js";
import { default as xU } from "./icons/Symbol/Common/BackupRestore.js";
import { default as FU } from "./icons/Symbol/Common/Bike.js";
import { default as hU } from "./icons/Symbol/Common/BluetoothOff.js";
import { default as OU } from "./icons/Symbol/Common/Bluetooth.js";
import { default as gU } from "./icons/Symbol/Common/Blur.js";
import { default as kU } from "./icons/Symbol/Common/BorderAll.js";
import { default as PU } from "./icons/Symbol/Common/BorderBottom.js";
import { default as GU } from "./icons/Symbol/Common/BorderHorizontal.js";
import { default as LU } from "./icons/Symbol/Common/BorderInside.js";
import { default as wU } from "./icons/Symbol/Common/BorderLeft.js";
import { default as yU } from "./icons/Symbol/Common/BorderNone.js";
import { default as EU } from "./icons/Symbol/Common/BorderOutside.js";
import { default as VU } from "./icons/Symbol/Common/BorderRight.js";
import { default as JU } from "./icons/Symbol/Common/BorderStyle.js";
import { default as zU } from "./icons/Symbol/Common/BorderTop.js";
import { default as qU } from "./icons/Symbol/Common/BorderVertical.js";
import { default as ZU } from "./icons/Symbol/Common/Cached.js";
import { default as jU } from "./icons/Symbol/Common/CameraFlip.js";
import { default as XU } from "./icons/Symbol/Common/CartPlus.js";
import { default as $U } from "./icons/Symbol/Common/ChartAreaspline.js";
import { default as eJ } from "./icons/Symbol/Common/ChartBarSquarePlus.js";
import { default as tJ } from "./icons/Symbol/Common/ChartBar.js";
import { default as lJ } from "./icons/Symbol/Common/ChartDonut.js";
import { default as nJ } from "./icons/Symbol/Common/ChartGroupBar.js";
import { default as cJ } from "./icons/Symbol/Common/ChartLineAlt.js";
import { default as sJ } from "./icons/Symbol/Common/ChartLineMelange.js";
import { default as mJ } from "./icons/Symbol/Common/ChartLine.js";
import { default as xJ } from "./icons/Symbol/Common/ChartSsid.js";
import { default as FJ } from "./icons/Symbol/Common/CheckAllOff.js";
import { default as hJ } from "./icons/Symbol/Common/CheckAll.js";
import { default as OJ } from "./icons/Symbol/Common/CheckSm.js";
import { default as gJ } from "./icons/Symbol/Common/CheckSync.js";
import { default as kJ } from "./icons/Symbol/Common/ChevronDoubleDown.js";
import { default as PJ } from "./icons/Symbol/Common/ChevronDoubleLeft.js";
import { default as GJ } from "./icons/Symbol/Common/ChevronDoubleRight.js";
import { default as LJ } from "./icons/Symbol/Common/ChevronDoubleUp.js";
import { default as wJ } from "./icons/Symbol/Common/ChevronDown.js";
import { default as yJ } from "./icons/Symbol/Common/ChevronLeftLarge.js";
import { default as EJ } from "./icons/Symbol/Common/ChevronRightLarge.js";
import { default as VJ } from "./icons/Symbol/Common/ChevronUp.js";
import { default as JJ } from "./icons/Symbol/Common/ClipboardCheck.js";
import { default as zJ } from "./icons/Symbol/Common/ClipboardClock.js";
import { default as qJ } from "./icons/Symbol/Common/ClipboardOff.js";
import { default as ZJ } from "./icons/Symbol/Common/Clipboard.js";
import { default as jJ } from "./icons/Symbol/Common/ClockPlus.js";
import { default as XJ } from "./icons/Symbol/Common/ClockSend.js";
import { default as $J } from "./icons/Symbol/Common/CloseSm.js";
import { default as eW } from "./icons/Symbol/Common/CloseXs.js";
import { default as tW } from "./icons/Symbol/Common/CodeOff.js";
import { default as lW } from "./icons/Symbol/Common/Contrast.js";
import { default as nW } from "./icons/Symbol/Common/CropRotate.js";
import { default as cW } from "./icons/Symbol/Common/Crop.js";
import { default as sW } from "./icons/Symbol/Common/CrosshairsOff.js";
import { default as mW } from "./icons/Symbol/Common/Crosshairs.js";
import { default as xW } from "./icons/Symbol/Common/CurrencyDollar.js";
import { default as FW } from "./icons/Symbol/Common/CurrencyEuro.js";
import { default as hW } from "./icons/Symbol/Common/DataTableCell.js";
import { default as OW } from "./icons/Symbol/Common/DataTableColumn.js";
import { default as gW } from "./icons/Symbol/Common/DataTableRow.js";
import { default as kW } from "./icons/Symbol/Common/Domain.js";
import { default as PW } from "./icons/Symbol/Common/DragHorizontalAlt.js";
import { default as GW } from "./icons/Symbol/Common/DragVerticalAlt.js";
import { default as LW } from "./icons/Symbol/Common/DragVertical.js";
import { default as wW } from "./icons/Symbol/Common/EarthOff.js";
import { default as yW } from "./icons/Symbol/Common/EarthSearch.js";
import { default as EW } from "./icons/Symbol/Common/Earth.js";
import { default as VW } from "./icons/Symbol/Common/FilterAltClose.js";
import { default as JW } from "./icons/Symbol/Common/FilterAltEdit.js";
import { default as zW } from "./icons/Symbol/Common/FilterAltMinus.js";
import { default as qW } from "./icons/Symbol/Common/FilterAltOff.js";
import { default as ZW } from "./icons/Symbol/Common/FilterAltPlus.js";
import { default as jW } from "./icons/Symbol/Common/FilterAltStar.js";
import { default as XW } from "./icons/Symbol/Common/FilterAlt.js";
import { default as $W } from "./icons/Symbol/Common/FormatHeader1.js";
import { default as ez } from "./icons/Symbol/Common/FormatHeader2.js";
import { default as tz } from "./icons/Symbol/Common/FormatHeader3.js";
import { default as lz } from "./icons/Symbol/Common/FormatHeader4.js";
import { default as nz } from "./icons/Symbol/Common/FormatHeader5.js";
import { default as cz } from "./icons/Symbol/Common/FormatHeader6.js";
import { default as sz } from "./icons/Symbol/Common/FormatHorizontalAlignCenter.js";
import { default as mz } from "./icons/Symbol/Common/FormatHorizontalAlignLeft.js";
import { default as xz } from "./icons/Symbol/Common/FormatHorizontalAlignRight.js";
import { default as Fz } from "./icons/Symbol/Common/FormatHorizontalExpandCenter.js";
import { default as hz } from "./icons/Symbol/Common/FormatStrikethroughAlt.js";
import { default as Oz } from "./icons/Symbol/Common/FormatTextPlus.js";
import { default as gz } from "./icons/Symbol/Common/FormatTextSize.js";
import { default as kz } from "./icons/Symbol/Common/FormatTextdirectionLToR.js";
import { default as Pz } from "./icons/Symbol/Common/FormatTextdirectionRToL.js";
import { default as Gz } from "./icons/Symbol/Common/FormatTitle.js";
import { default as Lz } from "./icons/Symbol/Common/FormatUnderline.js";
import { default as wz } from "./icons/Symbol/Common/FormatVerticalAlignBottom.js";
import { default as yz } from "./icons/Symbol/Common/FormatVerticalAlignCenter.js";
import { default as Ez } from "./icons/Symbol/Common/FormatVerticalAlignTop.js";
import { default as Vz } from "./icons/Symbol/Common/Forward10.js";
import { default as Jz } from "./icons/Symbol/Common/Forward15.js";
import { default as zz } from "./icons/Symbol/Common/Forward30.js";
import { default as qz } from "./icons/Symbol/Common/Forward5.js";
import { default as Zz } from "./icons/Symbol/Common/Forward.js";
import { default as jz } from "./icons/Symbol/Common/Globe.js";
import { default as Xz } from "./icons/Symbol/Common/GraphicEq.js";
import { default as $z } from "./icons/Symbol/Common/ImageResizeLg.js";
import { default as eN } from "./icons/Symbol/Common/ImageResizeSm.js";
import { default as tN } from "./icons/Symbol/Common/Information.js";
import { default as lN } from "./icons/Symbol/Common/LinkClose.js";
import { default as nN } from "./icons/Symbol/Common/LinkPlus.js";
import { default as cN } from "./icons/Symbol/Common/Login.js";
import { default as sN } from "./icons/Symbol/Common/Logout.js";
import { default as mN } from "./icons/Symbol/Common/MapMarkerEdit.js";
import { default as xN } from "./icons/Symbol/Common/Math.js";
import { default as FN } from "./icons/Symbol/Common/MenuBurger.js";
import { default as hN } from "./icons/Symbol/Common/MenuDown.js";
import { default as ON } from "./icons/Symbol/Common/MenuLeft.js";
import { default as gN } from "./icons/Symbol/Common/MenuRight.js";
import { default as kN } from "./icons/Symbol/Common/MenuSwap.js";
import { default as PN } from "./icons/Symbol/Common/MenuUp.js";
import { default as GN } from "./icons/Symbol/Common/MinusSearchAlt.js";
import { default as LN } from "./icons/Symbol/Common/MinusSearch.js";
import { default as wN } from "./icons/Symbol/Common/Minus.js";
import { default as yN } from "./icons/Symbol/Common/MoreDotsHorizontal.js";
import { default as EN } from "./icons/Symbol/Common/MoreDotsVertical.js";
import { default as VN } from "./icons/Symbol/Common/OpenInNewOff.js";
import { default as JN } from "./icons/Symbol/Common/PageFirst.js";
import { default as zN } from "./icons/Symbol/Common/PageLast.js";
import { default as qN } from "./icons/Symbol/Common/Paperclip.js";
import { default as ZN } from "./icons/Symbol/Common/PeopleExchange.js";
import { default as jN } from "./icons/Symbol/Common/Plane.js";
import { default as XN } from "./icons/Symbol/Common/PlusSearchAlt.js";
import { default as $N } from "./icons/Symbol/Common/PlusSearch.js";
import { default as eq } from "./icons/Symbol/Common/Plus.js";
import { default as tq } from "./icons/Symbol/Common/Poll.js";
import { default as lq } from "./icons/Symbol/Common/Progress1.js";
import { default as nq } from "./icons/Symbol/Common/Progress2.js";
import { default as cq } from "./icons/Symbol/Common/Progress3.js";
import { default as sq } from "./icons/Symbol/Common/Progress4.js";
import { default as mq } from "./icons/Symbol/Common/Progress5.js";
import { default as xq } from "./icons/Symbol/Common/Progress6.js";
import { default as Fq } from "./icons/Symbol/Common/Progress7.js";
import { default as hq } from "./icons/Symbol/Common/Progress8.js";
import { default as Oq } from "./icons/Symbol/Common/PublishOff.js";
import { default as gq } from "./icons/Symbol/Common/Redo.js";
import { default as kq } from "./icons/Symbol/Common/RepeatAlt.js";
import { default as Pq } from "./icons/Symbol/Common/Replace.js";
import { default as Gq } from "./icons/Symbol/Common/Replay10.js";
import { default as Lq } from "./icons/Symbol/Common/Replay15.js";
import { default as wq } from "./icons/Symbol/Common/Replay30.js";
import { default as yq } from "./icons/Symbol/Common/Replay5.js";
import { default as Eq } from "./icons/Symbol/Common/Replay.js";
import { default as Vq } from "./icons/Symbol/Common/ReplyAltReversed.js";
import { default as Jq } from "./icons/Symbol/Common/ReplyAlt.js";
import { default as zq } from "./icons/Symbol/Common/ReplyMultipleAltReversed.js";
import { default as qq } from "./icons/Symbol/Common/ReplyMultipleAlt.js";
import { default as Zq } from "./icons/Symbol/Common/ReplyMultiple.js";
import { default as jq } from "./icons/Symbol/Common/ResizeHandle.js";
import { default as Xq } from "./icons/Symbol/Common/Restore.js";
import { default as $q } from "./icons/Symbol/Common/Roadmap.js";
import { default as eQ } from "./icons/Symbol/Common/RotateLock.js";
import { default as tQ } from "./icons/Symbol/Common/RouteDirectionHorizontal.js";
import { default as lQ } from "./icons/Symbol/Common/RouteDirectionVertical.js";
import { default as nQ } from "./icons/Symbol/Common/ScissorCut.js";
import { default as cQ } from "./icons/Symbol/Common/SearchAlt.js";
import { default as sQ } from "./icons/Symbol/Common/SelectDrag.js";
import { default as mQ } from "./icons/Symbol/Common/ShareIos.js";
import { default as xQ } from "./icons/Symbol/Common/SortAlphabeticalAscending.js";
import { default as FQ } from "./icons/Symbol/Common/SortAlphabeticalDescending.js";
import { default as hQ } from "./icons/Symbol/Common/SortDown.js";
import { default as OQ } from "./icons/Symbol/Common/SortLeft.js";
import { default as gQ } from "./icons/Symbol/Common/SortReverse.js";
import { default as kQ } from "./icons/Symbol/Common/SortRight.js";
import { default as PQ } from "./icons/Symbol/Common/SortUp.js";
import { default as GQ } from "./icons/Symbol/Common/Sort.js";
import { default as LQ } from "./icons/Symbol/Common/StarHalfFull.js";
import { default as wQ } from "./icons/Symbol/Common/SubdirectoryArrowLeft.js";
import { default as yQ } from "./icons/Symbol/Common/SwapHorizontal.js";
import { default as EQ } from "./icons/Symbol/Common/SwapSkew.js";
import { default as VQ } from "./icons/Symbol/Common/SwapVertical.js";
import { default as JQ } from "./icons/Symbol/Common/SwipeLeft.js";
import { default as zQ } from "./icons/Symbol/Common/SwipeRight.js";
import { default as qQ } from "./icons/Symbol/Common/SyncAlt.js";
import { default as ZQ } from "./icons/Symbol/Common/SyncLock.js";
import { default as jQ } from "./icons/Symbol/Common/SyncOff.js";
import { default as XQ } from "./icons/Symbol/Common/TextCheck.js";
import { default as $Q } from "./icons/Symbol/Common/TextEdit.js";
import { default as eZ } from "./icons/Symbol/Common/TextLong.js";
import { default as tZ } from "./icons/Symbol/Common/TextSearch.js";
import { default as lZ } from "./icons/Symbol/Common/TextSquarePlus.js";
import { default as nZ } from "./icons/Symbol/Common/Text.js";
import { default as cZ } from "./icons/Symbol/Common/ThreeDRotation.js";
import { default as sZ } from "./icons/Symbol/Common/Translate.js";
import { default as mZ } from "./icons/Symbol/Common/TrayDownload.js";
import { default as xZ } from "./icons/Symbol/Common/TrayUpload.js";
import { default as FZ } from "./icons/Symbol/Common/TuneVertical.js";
import { default as hZ } from "./icons/Symbol/Common/Undo.js";
import { default as OZ } from "./icons/Symbol/Common/UnfoldLessHorizontal.js";
import { default as gZ } from "./icons/Symbol/Common/UnfoldLessVertical.js";
import { default as kZ } from "./icons/Symbol/Common/UnfoldMoreHorizontal.js";
import { default as PZ } from "./icons/Symbol/Common/UnfoldMoreVertical.js";
import { default as GZ } from "./icons/Symbol/Common/WifiOff.js";
import { default as LZ } from "./icons/Symbol/Common/Wifi.js";
import { default as wZ } from "./icons-legacy/AvatarDefaultSquare.js";
import { default as yZ } from "./icons-legacy/DocNotActive.js";
import { default as EZ } from "./icons-legacy/Hashtag.js";
import { default as VZ } from "./icons-legacy/IconAccessTime.js";
import { default as JZ } from "./icons-legacy/IconAccountTree.js";
import { default as zZ } from "./icons-legacy/IconAdd.js";
import { default as qZ } from "./icons-legacy/IconAddCircle.js";
import { default as ZZ } from "./icons-legacy/IconAddFile.js";
import { default as jZ } from "./icons-legacy/IconAddImage.js";
import { default as XZ } from "./icons-legacy/IconAddPhoto.js";
import { default as $Z } from "./icons-legacy/IconAddTemplates.js";
import { default as e1 } from "./icons-legacy/IconAddpage.js";
import { default as t1 } from "./icons-legacy/IconAI.js";
import { default as l1 } from "./icons-legacy/IconApps.js";
import { default as n1 } from "./icons-legacy/IconArchive.js";
import { default as c1 } from "./icons-legacy/IconArchiveArrow.js";
import { default as s1 } from "./icons-legacy/IconArrange.js";
import { default as m1 } from "./icons-legacy/IconArrowBack.js";
import { default as x1 } from "./icons-legacy/IconArrowDropBoth.js";
import { default as F1 } from "./icons-legacy/IconArrowDropDown.js";
import { default as h1 } from "./icons-legacy/IconArrowDropUp.js";
import { default as O1 } from "./icons-legacy/IconArrowForward.js";
import { default as g1 } from "./icons-legacy/IconArrowUpward.js";
import { default as k1 } from "./icons-legacy/IconAttachment.js";
import { default as P1 } from "./icons-legacy/IconAwesometable.js";
import { default as G1 } from "./icons-legacy/IconBarChart.js";
import { default as L1 } from "./icons-legacy/IconBookmark.js";
import { default as w1 } from "./icons-legacy/IconBookmarkBorder.js";
import { default as y1 } from "./icons-legacy/IconBookmarks.js";
import { default as E1 } from "./icons-legacy/IconBorderRadius.js";
import { default as V1 } from "./icons-legacy/IconBrokenImage.js";
import { default as J1 } from "./icons-legacy/IconBrush.js";
import { default as z1 } from "./icons-legacy/IconBulb.js";
import { default as q1 } from "./icons-legacy/IconBusiness.js";
import { default as Z1 } from "./icons-legacy/IconCake.js";
import { default as j1 } from "./icons-legacy/IconCalendar.js";
import { default as X1 } from "./icons-legacy/IconCategory.js";
import { default as $1 } from "./icons-legacy/IconCenterFocus.js";
import { default as e5 } from "./icons-legacy/IconCenterFocusStrong.js";
import { default as t5 } from "./icons-legacy/IconCenterTarget.js";
import { default as l5 } from "./icons-legacy/IconChain.js";
import { default as n5 } from "./icons-legacy/IconChannels.js";
import { default as c5 } from "./icons-legacy/IconChannelsBox.js";
import { default as s5 } from "./icons-legacy/IconChannelsColor.js";
import { default as m5 } from "./icons-legacy/IconChannelsDuotone.js";
import { default as x5 } from "./icons-legacy/IconChat.js";
import { default as F5 } from "./icons-legacy/IconCheck.js";
import { default as h5 } from "./icons-legacy/IconCheckBox.js";
import { default as O5 } from "./icons-legacy/IconCheckBoxOutline.js";
import { default as g5 } from "./icons-legacy/IconCheckBoxOutlineBlank.js";
import { default as k5 } from "./icons-legacy/IconCheckCircle.js";
import { default as P5 } from "./icons-legacy/IconChevronLeft.js";
import { default as G5 } from "./icons-legacy/IconChevronRight.js";
import { default as L5 } from "./icons-legacy/IconCircleBlank.js";
import { default as w5 } from "./icons-legacy/IconClose.js";
import { default as y5 } from "./icons-legacy/IconCode.js";
import { default as E5 } from "./icons-legacy/IconCog.js";
import { default as V5 } from "./icons-legacy/IconCogs.js";
import { default as J5 } from "./icons-legacy/IconComment.js";
import { default as z5 } from "./icons-legacy/IconCommute.js";
import { default as q5 } from "./icons-legacy/IconCompareArrows.js";
import { default as Z5 } from "./icons-legacy/IconCopy.js";
import { default as j5 } from "./icons-legacy/IconCopyFile.js";
import { default as X5 } from "./icons-legacy/IconCreate.js";
import { default as $5 } from "./icons-legacy/IconCustomEmoji.js";
import { default as eK } from "./icons-legacy/IconDelete.js";
import { default as tK } from "./icons-legacy/IconDeleteCircle.js";
import { default as lK } from "./icons-legacy/IconDirectionsBike.js";
import { default as nK } from "./icons-legacy/IconDownload.js";
import { default as cK } from "./icons-legacy/IconDraft.js";
import { default as sK } from "./icons-legacy/IconDragHandleHorizontal.js";
import { default as mK } from "./icons-legacy/IconDragHandleVertical.js";
import { default as xK } from "./icons-legacy/IconDragHorizontal.js";
import { default as FK } from "./icons-legacy/IconDragIndicator.js";
import { default as hK } from "./icons-legacy/IconDrive.js";
import { default as OK } from "./icons-legacy/IconEdit.js";
import { default as gK } from "./icons-legacy/IconEmail.js";
import { default as kK } from "./icons-legacy/IconEmojiOff.js";
import { default as PK } from "./icons-legacy/IconEmojiPlus.js";
import { default as GK } from "./icons-legacy/IconError.js";
import { default as LK } from "./icons-legacy/IconEvent.js";
import { default as wK } from "./icons-legacy/IconExitToApp.js";
import { default as yK } from "./icons-legacy/IconExpand.js";
import { default as EK } from "./icons-legacy/IconExpandLess.js";
import { default as VK } from "./icons-legacy/IconExpandMore.js";
import { default as JK } from "./icons-legacy/IconExternalLink.js";
import { default as zK } from "./icons-legacy/IconFace.js";
import { default as qK } from "./icons-legacy/IconFacebook.js";
import { default as ZK } from "./icons-legacy/IconFastfood.js";
import { default as jK } from "./icons-legacy/IconFavorite.js";
import { default as XK } from "./icons-legacy/IconFavoriteBorder.js";
import { default as $K } from "./icons-legacy/IconFileAdobeAfterEffects.js";
import { default as e0 } from "./icons-legacy/IconFileAdobeAnimate.js";
import { default as t0 } from "./icons-legacy/IconFileAdobeIllustrator.js";
import { default as l0 } from "./icons-legacy/IconFileAdobeIndesign.js";
import { default as n0 } from "./icons-legacy/IconFileAdobePhotoshop.js";
import { default as c0 } from "./icons-legacy/IconFileAdobePremiere.js";
import { default as s0 } from "./icons-legacy/IconFileAodocs.js";
import { default as m0 } from "./icons-legacy/IconFileBrokenFile.js";
import { default as x0 } from "./icons-legacy/IconFileChannels.js";
import { default as F0 } from "./icons-legacy/IconFileCode.js";
import { default as h0 } from "./icons-legacy/IconFileCodeColor.js";
import { default as O0 } from "./icons-legacy/IconFileDownload.js";
import { default as g0 } from "./icons-legacy/IconFileFolderColor.js";
import { default as k0 } from "./icons-legacy/IconFileFolderFile.js";
import { default as P0 } from "./icons-legacy/IconFileGDocs.js";
import { default as G0 } from "./icons-legacy/IconFileGDocsColor.js";
import { default as L0 } from "./icons-legacy/IconFileGDocsFile.js";
import { default as w0 } from "./icons-legacy/IconFileGDraw.js";
import { default as y0 } from "./icons-legacy/IconFileGDrawColor.js";
import { default as E0 } from "./icons-legacy/IconFileGDrawFile.js";
import { default as V0 } from "./icons-legacy/IconFileGForms.js";
import { default as J0 } from "./icons-legacy/IconFileGFormsColor.js";
import { default as z0 } from "./icons-legacy/IconFileGFormsFile.js";
import { default as q0 } from "./icons-legacy/IconFileGSheets.js";
import { default as Z0 } from "./icons-legacy/IconFileGSheetsColor.js";
import { default as j0 } from "./icons-legacy/IconFileGSheetsFile.js";
import { default as X0 } from "./icons-legacy/IconFileGSlides.js";
import { default as $0 } from "./icons-legacy/IconFileGSlidesColor.js";
import { default as ej } from "./icons-legacy/IconFileGSlidesFile.js";
import { default as tj } from "./icons-legacy/IconFileGeneralFile.js";
import { default as lj } from "./icons-legacy/IconFileGeneric.js";
import { default as nj } from "./icons-legacy/IconFileGenericColor.js";
import { default as cj } from "./icons-legacy/IconFileGif.js";
import { default as sj } from "./icons-legacy/IconFileGifColor.js";
import { default as mj } from "./icons-legacy/IconFileImageColor.js";
import { default as xj } from "./icons-legacy/IconFileImgFile.js";
import { default as Fj } from "./icons-legacy/IconFileLink.js";
import { default as hj } from "./icons-legacy/IconFileLinkColor.js";
import { default as Oj } from "./icons-legacy/IconFileMsAccess.js";
import { default as gj } from "./icons-legacy/IconFileMsAccessColor.js";
import { default as kj } from "./icons-legacy/IconFileMsExcel.js";
import { default as Pj } from "./icons-legacy/IconFileMsExcelColor.js";
import { default as Gj } from "./icons-legacy/IconFileMsOnenoteColor.js";
import { default as Lj } from "./icons-legacy/IconFileMsPowerpoint.js";
import { default as wj } from "./icons-legacy/IconFileMsPowerpointColor.js";
import { default as yj } from "./icons-legacy/IconFileMsPublisher.js";
import { default as Ej } from "./icons-legacy/IconFileMsPublisherColor.js";
import { default as Vj } from "./icons-legacy/IconFileMsVisioColor.js";
import { default as Jj } from "./icons-legacy/IconFileMsWord.js";
import { default as zj } from "./icons-legacy/IconFileMsWordColor.js";
import { default as qj } from "./icons-legacy/IconFileMusic.js";
import { default as Zj } from "./icons-legacy/IconFileMusicColor.js";
import { default as jj } from "./icons-legacy/IconFileMusicFile.js";
import { default as Xj } from "./icons-legacy/IconFilePages.js";
import { default as $j } from "./icons-legacy/IconFilePdf.js";
import { default as eY } from "./icons-legacy/IconFilePdfAlt.js";
import { default as tY } from "./icons-legacy/IconFilePdfAltColor.js";
import { default as lY } from "./icons-legacy/IconFilePdfColor.js";
import { default as nY } from "./icons-legacy/IconFilePdfFile.js";
import { default as cY } from "./icons-legacy/IconFilePeople.js";
import { default as sY } from "./icons-legacy/IconFilePerson.js";
import { default as mY } from "./icons-legacy/IconFilePersonColor.js";
import { default as xY } from "./icons-legacy/IconFileSound.js";
import { default as FY } from "./icons-legacy/IconFileSoundColor.js";
import { default as hY } from "./icons-legacy/IconFileSoundFile.js";
import { default as OY } from "./icons-legacy/IconFileText.js";
import { default as gY } from "./icons-legacy/IconFileTextColor.js";
import { default as kY } from "./icons-legacy/IconFileTxtFile.js";
import { default as PY } from "./icons-legacy/IconFileUpload.js";
import { default as GY } from "./icons-legacy/IconFileVideo.js";
import { default as LY } from "./icons-legacy/IconFileVideoColor.js";
import { default as wY } from "./icons-legacy/IconFileVideoFile.js";
import { default as yY } from "./icons-legacy/IconFileZip.js";
import { default as EY } from "./icons-legacy/IconFileZipColor.js";
import { default as VY } from "./icons-legacy/IconFiles.js";
import { default as JY } from "./icons-legacy/IconFilter.js";
import { default as zY } from "./icons-legacy/IconFilterList.js";
import { default as qY } from "./icons-legacy/IconFlag.js";
import { default as ZY } from "./icons-legacy/IconFlight.js";
import { default as jY } from "./icons-legacy/IconFolder.js";
import { default as XY } from "./icons-legacy/IconFolderColor.js";
import { default as $Y } from "./icons-legacy/IconFolderDrive.js";
import { default as e2 } from "./icons-legacy/IconFolderRecent.js";
import { default as t2 } from "./icons-legacy/IconFormatAlignCenter.js";
import { default as l2 } from "./icons-legacy/IconFormatAlignJustify.js";
import { default as n2 } from "./icons-legacy/IconFormatAlignLeft.js";
import { default as c2 } from "./icons-legacy/IconFormatAlignRight.js";
import { default as s2 } from "./icons-legacy/IconFormatBold.js";
import { default as m2 } from "./icons-legacy/IconFormatClear.js";
import { default as x2 } from "./icons-legacy/IconFormatColorFill.js";
import { default as F2 } from "./icons-legacy/IconFormatColorReset.js";
import { default as h2 } from "./icons-legacy/IconFormatColorText.js";
import { default as O2 } from "./icons-legacy/IconFormatIndentDecrease.js";
import { default as g2 } from "./icons-legacy/IconFormatIndentIncrease.js";
import { default as k2 } from "./icons-legacy/IconFormatItalic.js";
import { default as P2 } from "./icons-legacy/IconFormatLineSpacing.js";
import { default as G2 } from "./icons-legacy/IconFormatListBulleted.js";
import { default as L2 } from "./icons-legacy/IconFormatListNumbered.js";
import { default as w2 } from "./icons-legacy/IconFormatQuote.js";
import { default as y2 } from "./icons-legacy/IconFormatSize.js";
import { default as E2 } from "./icons-legacy/IconFormatStrikethrough.js";
import { default as V2 } from "./icons-legacy/IconFormatUnderlined.js";
import { default as J2 } from "./icons-legacy/IconForum.js";
import { default as z2 } from "./icons-legacy/IconFrame.js";
import { default as q2 } from "./icons-legacy/IconFullscreen.js";
import { default as Z2 } from "./icons-legacy/IconFullscreenExit.js";
import { default as j2 } from "./icons-legacy/IconGDocsColor.js";
import { default as X2 } from "./icons-legacy/IconGDrawColor.js";
import { default as $2 } from "./icons-legacy/IconGFormsColor.js";
import { default as e3 } from "./icons-legacy/IconGSheetsColor.js";
import { default as t3 } from "./icons-legacy/IconGSlideColor.js";
import { default as l3 } from "./icons-legacy/IconGetApp.js";
import { default as n3 } from "./icons-legacy/IconGif.js";
import { default as c3 } from "./icons-legacy/IconGoogle.js";
import { default as s3 } from "./icons-legacy/IconGraph.js";
import { default as m3 } from "./icons-legacy/IconGroup.js";
import { default as x3 } from "./icons-legacy/IconGroupAdd.js";
import { default as F3 } from "./icons-legacy/IconGroupAvatar.js";
import { default as h3 } from "./icons-legacy/IconGroupWork.js";
import { default as O3 } from "./icons-legacy/IconHangouts.js";
import { default as g3 } from "./icons-legacy/IconHashtag.js";
import { default as k3 } from "./icons-legacy/IconHashtagColor.js";
import { default as P3 } from "./icons-legacy/IconHeartLine.js";
import { default as G3 } from "./icons-legacy/IconHelp.js";
import { default as L3 } from "./icons-legacy/IconHidden.js";
import { default as w3 } from "./icons-legacy/IconHistory.js";
import { default as y3 } from "./icons-legacy/IconHome.js";
import { default as E3 } from "./icons-legacy/IconImage.js";
import { default as V3 } from "./icons-legacy/IconImageAlignCenter.js";
import { default as J3 } from "./icons-legacy/IconImageAlignLeft.js";
import { default as z3 } from "./icons-legacy/IconImageAlignRight.js";
import { default as q3 } from "./icons-legacy/IconImageColor.js";
import { default as Z3 } from "./icons-legacy/IconImageHeroCover.js";
import { default as j3 } from "./icons-legacy/IconImageHeroTop.js";
import { default as X3 } from "./icons-legacy/IconImageInline.js";
import { default as $3 } from "./icons-legacy/IconImageOverflowCenter.js";
import { default as e4 } from "./icons-legacy/IconImageOverflowLeft.js";
import { default as t4 } from "./icons-legacy/IconImageOverflowRight.js";
import { default as l4 } from "./icons-legacy/IconImageResize.js";
import { default as n4 } from "./icons-legacy/IconImageWrap.js";
import { default as c4 } from "./icons-legacy/IconImgInlineFull.js";
import { default as s4 } from "./icons-legacy/IconInfo.js";
import { default as m4 } from "./icons-legacy/IconInfoRound.js";
import { default as x4 } from "./icons-legacy/IconInfoRoundOutline.js";
import { default as F4 } from "./icons-legacy/IconInsertCaption.js";
import { default as h4 } from "./icons-legacy/IconInsertEmoticon.js";
import { default as O4 } from "./icons-legacy/IconInsertLine.js";
import { default as g4 } from "./icons-legacy/IconInsertTable.js";
import { default as k4 } from "./icons-legacy/IconInstagram.js";
import { default as P4 } from "./icons-legacy/IconLanguage.js";
import { default as G4 } from "./icons-legacy/IconLaunch.js";
import { default as L4 } from "./icons-legacy/IconLauncher.js";
import { default as w4 } from "./icons-legacy/IconLayout.js";
import { default as y4 } from "./icons-legacy/IconLike.js";
import { default as E4 } from "./icons-legacy/IconLineChart.js";
import { default as V4 } from "./icons-legacy/IconLink.js";
import { default as J4 } from "./icons-legacy/IconLinkOff.js";
import { default as z4 } from "./icons-legacy/IconLocalFlorist.js";
import { default as q4 } from "./icons-legacy/IconLocation.js";
import { default as Z4 } from "./icons-legacy/IconLocationCity.js";
import { default as j4 } from "./icons-legacy/IconLocationOn.js";
import { default as X4 } from "./icons-legacy/IconLock.js";
import { default as $4 } from "./icons-legacy/IconLockOpen.js";
import { default as e6 } from "./icons-legacy/IconLogoAzure.js";
import { default as t6 } from "./icons-legacy/IconLogoAzureColour.js";
import { default as l6 } from "./icons-legacy/IconLogoFacebook.js";
import { default as n6 } from "./icons-legacy/IconLogoFacebookColor.js";
import { default as c6 } from "./icons-legacy/IconLogoGAnalytics.js";
import { default as s6 } from "./icons-legacy/IconLogoGAnalyticsColor.js";
import { default as m6 } from "./icons-legacy/IconLogoGCalendarColor.js";
import { default as x6 } from "./icons-legacy/IconLogoGChat.js";
import { default as F6 } from "./icons-legacy/IconLogoGChatColor.js";
import { default as h6 } from "./icons-legacy/IconLogoGDrive.js";
import { default as O6 } from "./icons-legacy/IconLogoGDriveColor.js";
import { default as g6 } from "./icons-legacy/IconLogoGHangouts.js";
import { default as k6 } from "./icons-legacy/IconLogoGHangoutsColor.js";
import { default as P6 } from "./icons-legacy/IconLogoGMeet.js";
import { default as G6 } from "./icons-legacy/IconLogoGMeetColor.js";
import { default as L6 } from "./icons-legacy/IconLogoGmail.js";
import { default as w6 } from "./icons-legacy/IconLogoInstagram.js";
import { default as y6 } from "./icons-legacy/IconLogoInstagramColor.js";
import { default as E6 } from "./icons-legacy/IconLogoJabberColor.js";
import { default as V6 } from "./icons-legacy/IconLogoLinkedin.js";
import { default as J6 } from "./icons-legacy/IconLogoLinkedinColor.js";
import { default as z6 } from "./icons-legacy/IconLogoOktaColor.js";
import { default as q6 } from "./icons-legacy/IconLogoSkype.js";
import { default as Z6 } from "./icons-legacy/IconLogoSkypeColor.js";
import { default as j6 } from "./icons-legacy/IconLogoSlackColor.js";
import { default as X6 } from "./icons-legacy/IconLogoTwitter.js";
import { default as $6 } from "./icons-legacy/IconLogoTwitterColor.js";
import { default as e7 } from "./icons-legacy/IconLogoWebexColor.js";
import { default as t7 } from "./icons-legacy/IconLogoWebexTeamsColor.js";
import { default as l7 } from "./icons-legacy/IconLogoZoom.js";
import { default as n7 } from "./icons-legacy/IconLogoZoomColor.js";
import { default as c7 } from "./icons-legacy/IconMelange.js";
import { default as s7 } from "./icons-legacy/IconMembers.js";
import { default as m7 } from "./icons-legacy/IconMembersCircle.js";
import { default as x7 } from "./icons-legacy/IconMenu.js";
import { default as F7 } from "./icons-legacy/IconMicrosoft.js";
import { default as h7 } from "./icons-legacy/IconMinusCircle.js";
import { default as O7 } from "./icons-legacy/IconMonetization.js";
import { default as g7 } from "./icons-legacy/IconMoreHoriz.js";
import { default as k7 } from "./icons-legacy/IconMoreVert.js";
import { default as P7 } from "./icons-legacy/IconMsAccess.js";
import { default as G7 } from "./icons-legacy/IconMsCalendar.js";
import { default as L7 } from "./icons-legacy/IconMsDelve.js";
import { default as w7 } from "./icons-legacy/IconMsDocumentLibrary.js";
import { default as y7 } from "./icons-legacy/IconMsExcel.js";
import { default as E7 } from "./icons-legacy/IconMsFolder.js";
import { default as V7 } from "./icons-legacy/IconMsForms.js";
import { default as J7 } from "./icons-legacy/IconMsOffice.js";
import { default as z7 } from "./icons-legacy/IconMsOnedrive.js";
import { default as q7 } from "./icons-legacy/IconMsOnenote.js";
import { default as Z7 } from "./icons-legacy/IconMsOutlook.js";
import { default as j7 } from "./icons-legacy/IconMsPowerpoint.js";
import { default as X7 } from "./icons-legacy/IconMsProject.js";
import { default as $7 } from "./icons-legacy/IconMsSharepoint.js";
import { default as e9 } from "./icons-legacy/IconMsSway.js";
import { default as t9 } from "./icons-legacy/IconMsTeams.js";
import { default as l9 } from "./icons-legacy/IconMsVisio.js";
import { default as n9 } from "./icons-legacy/IconMsWord.js";
import { default as c9 } from "./icons-legacy/IconMyDraft.js";
import { default as s9 } from "./icons-legacy/IconNewReleases.js";
import { default as m9 } from "./icons-legacy/IconNotifications.js";
import { default as x9 } from "./icons-legacy/IconNotificationsActive.js";
import { default as F9 } from "./icons-legacy/IconNotificationsOff.js";
import { default as h9 } from "./icons-legacy/IconNotificationsOn.js";
import { default as O9 } from "./icons-legacy/IconOpenInNew.js";
import { default as g9 } from "./icons-legacy/IconOrgChart.js";
import { default as k9 } from "./icons-legacy/IconPage.js";
import { default as P9 } from "./icons-legacy/IconPageOld.js";
import { default as G9 } from "./icons-legacy/IconPageSubpage.js";
import { default as L9 } from "./icons-legacy/IconPagegroup.js";
import { default as w9 } from "./icons-legacy/IconPagegroupAlt.js";
import { default as y9 } from "./icons-legacy/IconPagegroupOld.js";
import { default as E9 } from "./icons-legacy/IconPages.js";
import { default as V9 } from "./icons-legacy/IconPagesBox.js";
import { default as J9 } from "./icons-legacy/IconPagesColor.js";
import { default as z9 } from "./icons-legacy/IconPagesDuotone.js";
import { default as q9 } from "./icons-legacy/IconPalette.js";
import { default as Z9 } from "./icons-legacy/IconPanelClose.js";
import { default as j9 } from "./icons-legacy/IconPanelOpen.js";
import { default as X9 } from "./icons-legacy/IconPeople.js";
import { default as $9 } from "./icons-legacy/IconPeopleAlt.js";
import { default as e8 } from "./icons-legacy/IconPeopleAltDuotone.js";
import { default as t8 } from "./icons-legacy/IconPeopleBox.js";
import { default as l8 } from "./icons-legacy/IconPeopleColor.js";
import { default as n8 } from "./icons-legacy/IconPeopleDuotone.js";
import { default as c8 } from "./icons-legacy/IconPerson.js";
import { default as s8 } from "./icons-legacy/IconPersonColor.js";
import { default as m8 } from "./icons-legacy/IconPhone.js";
import { default as x8 } from "./icons-legacy/IconPhoneAndroid.js";
import { default as F8 } from "./icons-legacy/IconPhoto.js";
import { default as h8 } from "./icons-legacy/IconPhotoSizeSelectLarge.js";
import { default as O8 } from "./icons-legacy/IconPinDrop.js";
import { default as g8 } from "./icons-legacy/IconPlayArrow.js";
import { default as k8 } from "./icons-legacy/IconPlayCircleFilled.js";
import { default as P8 } from "./icons-legacy/IconPrint.js";
import { default as G8 } from "./icons-legacy/IconPublish.js";
import { default as L8 } from "./icons-legacy/IconQuestion.js";
import { default as w8 } from "./icons-legacy/IconQuote.js";
import { default as y8 } from "./icons-legacy/IconQuoteInline.js";
import { default as E8 } from "./icons-legacy/IconQuoteWrap.js";
import { default as V8 } from "./icons-legacy/IconRefresh.js";
import { default as J8 } from "./icons-legacy/IconRemove.js";
import { default as z8 } from "./icons-legacy/IconRemoveCircle.js";
import { default as q8 } from "./icons-legacy/IconRemoveCircleOutline.js";
import { default as Z8 } from "./icons-legacy/IconReply.js";
import { default as j8 } from "./icons-legacy/IconResizeBottomRight.js";
import { default as X8 } from "./icons-legacy/IconResizeDrag.js";
import { default as $8 } from "./icons-legacy/IconRetweet.js";
import { default as eX } from "./icons-legacy/IconReview.js";
import { default as tX } from "./icons-legacy/IconReviewDraft.js";
import { default as lX } from "./icons-legacy/IconRocket.js";
import { default as nX } from "./icons-legacy/IconRotateLeft.js";
import { default as cX } from "./icons-legacy/IconRotateRight.js";
import { default as sX } from "./icons-legacy/IconRss.js";
import { default as mX } from "./icons-legacy/IconRssFeed.js";
import { default as xX } from "./icons-legacy/IconScanner.js";
import { default as FX } from "./icons-legacy/IconSearch.js";
import { default as hX } from "./icons-legacy/IconSend.js";
import { default as OX } from "./icons-legacy/IconSettings.js";
import { default as gX } from "./icons-legacy/IconShadow.js";
import { default as kX } from "./icons-legacy/IconSharedDraft.js";
import { default as PX } from "./icons-legacy/IconSliders.js";
import { default as GX } from "./icons-legacy/IconSms.js";
import { default as LX } from "./icons-legacy/IconSocial.js";
import { default as wX } from "./icons-legacy/IconSortAlpha.js";
import { default as yX } from "./icons-legacy/IconSortAlphaReverse.js";
import { default as EX } from "./icons-legacy/IconStar.js";
import { default as VX } from "./icons-legacy/IconStarBorder.js";
import { default as JX } from "./icons-legacy/IconStream.js";
import { default as zX } from "./icons-legacy/IconStrikethroughS.js";
import { default as qX } from "./icons-legacy/IconStructure.js";
import { default as ZX } from "./icons-legacy/IconSubdirectory.js";
import { default as jX } from "./icons-legacy/IconSubdirectoryArrowRight.js";
import { default as XX } from "./icons-legacy/IconSupervisedUserCircle.js";
import { default as $X } from "./icons-legacy/IconSync.js";
import { default as e_ } from "./icons-legacy/IconTag.js";
import { default as t_ } from "./icons-legacy/IconTextFormat.js";
import { default as l_ } from "./icons-legacy/IconTextHighlight.js";
import { default as n_ } from "./icons-legacy/IconThumbDown.js";
import { default as c_ } from "./icons-legacy/IconThumbUp.js";
import { default as s_ } from "./icons-legacy/IconTime.js";
import { default as m_ } from "./icons-legacy/IconToggleOff.js";
import { default as x_ } from "./icons-legacy/IconToggleOn.js";
import { default as F_ } from "./icons-legacy/IconTrendingDown.js";
import { default as h_ } from "./icons-legacy/IconTrendingFlat.js";
import { default as O_ } from "./icons-legacy/IconTrendingUp.js";
import { default as g_ } from "./icons-legacy/IconTune.js";
import { default as k_ } from "./icons-legacy/IconTwitter.js";
import { default as P_ } from "./icons-legacy/IconUnarchive.js";
import { default as G_ } from "./icons-legacy/IconUnpublish.js";
import { default as L_ } from "./icons-legacy/IconUnsplash.js";
import { default as w_ } from "./icons-legacy/IconUpdate.js";
import { default as y_ } from "./icons-legacy/IconUpload.js";
import { default as E_ } from "./icons-legacy/IconUser.js";
import { default as V_ } from "./icons-legacy/IconUserAdd.js";
import { default as J_ } from "./icons-legacy/IconUsers.js";
import { default as z_ } from "./icons-legacy/IconVideocam.js";
import { default as q_ } from "./icons-legacy/IconViewList.js";
import { default as Z_ } from "./icons-legacy/IconViewModule.js";
import { default as j_ } from "./icons-legacy/IconViewQuilt.js";
import { default as X_ } from "./icons-legacy/IconViewStream.js";
import { default as $_ } from "./icons-legacy/IconVisibility.js";
import { default as e$ } from "./icons-legacy/IconVisibilityOff.js";
import { default as t$ } from "./icons-legacy/IconVisibilityOn.js";
import { default as l$ } from "./icons-legacy/IconVisible.js";
import { default as n$ } from "./icons-legacy/IconWarning.js";
import { default as c$ } from "./icons-legacy/IconWidgetBreadcrumb.js";
import { default as s$ } from "./icons-legacy/IconWidgetButton.js";
import { default as m$ } from "./icons-legacy/IconWidgetChannelFeed.js";
import { default as x$ } from "./icons-legacy/IconWidgetFiles.js";
import { default as F$ } from "./icons-legacy/IconWidgetImage.js";
import { default as h$ } from "./icons-legacy/IconWidgetLinkList.js";
import { default as O$ } from "./icons-legacy/IconWidgetSpacer.js";
import { default as g$ } from "./icons-legacy/IconWidgetText.js";
import { default as k$ } from "./icons-legacy/IconWidgetVideo.js";
import { default as P$ } from "./icons-legacy/IconZoomIn.js";
import { default as G$ } from "./icons-legacy/IconZoomOut.js";
import { default as L$ } from "./icons-legacy/LogoGoogleFonts.js";
import { default as w$ } from "./icons-legacy/PagesPlus.js";
import { default as y$ } from "./icons-legacy/WebsiteNotActive.js";
import { default as E$ } from "./icons-legacy/WikiNotActive.js";
export {
  wZ as AvatarDefaultSquare,
  yZ as DocNotActive,
  EZ as Hashtag,
  t1 as IconAI,
  VZ as IconAccessTime,
  FV as IconAccessibility,
  xV as IconAccessibilityAlt,
  OV as IconAccessible,
  hV as IconAccessibleForward,
  Jd as IconAccountCircleFill,
  $k as IconAccountCircleOutline,
  zd as IconAccountClipboardFill,
  eb as IconAccountClipboardOutline,
  qd as IconAccountCogFill,
  tb as IconAccountCogOutline,
  nm as IconAccountFill,
  Zd as IconAccountMinusFill,
  lb as IconAccountMinusOutline,
  em as IconAccountMultipleFill,
  jd as IconAccountMultipleMinusFill,
  nb as IconAccountMultipleMinusOutline,
  Xd as IconAccountMultipleOffFill,
  cb as IconAccountMultipleOffOutline,
  mb as IconAccountMultipleOutline,
  $d as IconAccountMultiplePlusFill,
  sb as IconAccountMultiplePlusOutline,
  hb as IconAccountOutline,
  tm as IconAccountPlusFill,
  xb as IconAccountPlusOutline,
  lm as IconAccountSquareFill,
  Fb as IconAccountSquareOutline,
  JZ as IconAccountTree,
  zZ as IconAdd,
  qZ as IconAddCircle,
  ZZ as IconAddFile,
  jZ as IconAddImage,
  XZ as IconAddPhoto,
  $Z as IconAddTemplates,
  e1 as IconAddpage,
  Ll as IconAdobeAcrobatFileColored,
  ju as IconAdobeAcrobatFileMonochrome,
  wl as IconAdobeAftereffectFileColored,
  Xu as IconAdobeAftereffectFileMonochrome,
  yl as IconAdobeAnimateFileColored,
  $u as IconAdobeAnimateFileMonochrome,
  El as IconAdobeIllustratorFileColored,
  ec as IconAdobeIllustratorFileMonochrome,
  Vl as IconAdobeIndesignFileColored,
  tc as IconAdobeIndesignFileMonochrome,
  Jl as IconAdobePhotoshopFileColored,
  lc as IconAdobePhotoshopFileMonochrome,
  zl as IconAdobePremiereFileColored,
  nc as IconAdobePremiereFileMonochrome,
  sm as IconAlarmFill,
  cm as IconAlarmOffFill,
  Ob as IconAlarmOffOutline,
  gb as IconAlarmOutline,
  kV as IconAlert,
  mm as IconAlertBellFill,
  kb as IconAlertBellOutline,
  xm as IconAlertCircleFill,
  Pb as IconAlertCircleOutline,
  Fm as IconAlertDecagramFill,
  Gb as IconAlertDecagramOutline,
  hm as IconAlertMapMarkerFill,
  Lb as IconAlertMapMarkerOutline,
  Om as IconAlertOctagonFill,
  wb as IconAlertOctagonOutline,
  gm as IconAlertRhombusFill,
  yb as IconAlertRhombusOutline,
  km as IconAlertShieldFill,
  Eb as IconAlertShieldOutline,
  gV as IconAlertSync,
  Pm as IconAlertTriangleFill,
  Vb as IconAlertTriangleOutline,
  PV as IconAlignHorizontalCenter,
  GV as IconAlignHorizontalDistribute,
  LV as IconAlignHorizontalLeft,
  wV as IconAlignHorizontalRight,
  yV as IconAlignVerticalBottom,
  EV as IconAlignVerticalDistribute,
  VV as IconAlignVerticalTop,
  r as IconAmericanExpressAppColored,
  Fr as IconAmericanExpressAppMonochrome,
  JV as IconAndroid,
  a as IconAndroidAppColored,
  hr as IconAndroidAppMonochrome,
  f as IconAodocsAppColored,
  Or as IconAodocsAppMonochrome,
  zV as IconApartment,
  u as IconAppleAppColored,
  gr as IconAppleAppMonochrome,
  l1 as IconApps,
  Gm as IconArViewFill,
  Jb as IconArViewOutline,
  n1 as IconArchive,
  c1 as IconArchiveArrow,
  Lm as IconArchiveFill,
  zb as IconArchiveOutline,
  s1 as IconArrange,
  m1 as IconArrowBack,
  ZV as IconArrowCollapse,
  qV as IconArrowCollapseAll,
  jV as IconArrowDown,
  x1 as IconArrowDropBoth,
  F1 as IconArrowDropDown,
  h1 as IconArrowDropUp,
  $V as IconArrowExpand,
  XV as IconArrowExpandAll,
  O1 as IconArrowForward,
  eU as IconArrowLeft,
  tU as IconArrowRight,
  lU as IconArrowUp,
  g1 as IconArrowUpward,
  wm as IconArticleFill,
  qb as IconArticleOutline,
  p as IconAsanaAppColored,
  kr as IconAsanaAppMonochrome,
  nU as IconAsterisk,
  cU as IconAt,
  d as IconAtlassianAppColored,
  Pr as IconAtlassianAppMonochrome,
  k1 as IconAttachment,
  sU as IconAutorenew,
  mU as IconAvTimer,
  P1 as IconAwesometable,
  i as IconAwesometableAppColored,
  Gr as IconAwesometableAppMonochrome,
  I as IconAwsAppColored,
  Lr as IconAwsAppMonochrome,
  C as IconAzureAppColored,
  wr as IconAzureAppMonochrome,
  ym as IconBackspaceFill,
  Zb as IconBackspaceOutline,
  xU as IconBackupRestore,
  Em as IconBadgeLgFill,
  jb as IconBadgeLgOutline,
  Vm as IconBadgeMdFill,
  Xb as IconBadgeMdOutline,
  Jm as IconBadgeSmFill,
  $b as IconBadgeSmOutline,
  zm as IconBankFill,
  eP as IconBankOutline,
  G1 as IconBarChart,
  qm as IconBasketballFill,
  tP as IconBasketballOutline,
  Zm as IconBellActiveFill,
  lP as IconBellActiveOutline,
  jm as IconBellCircleFill,
  nP as IconBellCircleOutline,
  Xm as IconBellClockFill,
  cP as IconBellClockOutline,
  $m as IconBellEditFill,
  sP as IconBellEditOutline,
  li as IconBellFill,
  ei as IconBellOffFill,
  mP as IconBellOffOutline,
  FP as IconBellOutline,
  ti as IconBellPlusFill,
  xP as IconBellPlusOutline,
  FU as IconBike,
  OU as IconBluetooth,
  hU as IconBluetoothOff,
  gU as IconBlur,
  ni as IconBoltFill,
  hP as IconBoltOutline,
  mi as IconBookOpenFill,
  kP as IconBookOpenOutline,
  ci as IconBookOpenPageFill,
  OP as IconBookOpenPageOutline,
  si as IconBookOpenTextFill,
  gP as IconBookOpenTextOutline,
  L1 as IconBookmark,
  w1 as IconBookmarkBorder,
  Oi as IconBookmarkFill,
  xi as IconBookmarkMultipleFill,
  PP as IconBookmarkMultipleOutline,
  Fi as IconBookmarkOffFill,
  GP as IconBookmarkOffOutline,
  wP as IconBookmarkOutline,
  hi as IconBookmarkPlusFill,
  LP as IconBookmarkPlusOutline,
  y1 as IconBookmarks,
  kU as IconBorderAll,
  PU as IconBorderBottom,
  GU as IconBorderHorizontal,
  LU as IconBorderInside,
  wU as IconBorderLeft,
  yU as IconBorderNone,
  EU as IconBorderOutside,
  E1 as IconBorderRadius,
  yP as IconBorderRadiusOutline,
  VU as IconBorderRight,
  JU as IconBorderStyle,
  zU as IconBorderTop,
  qU as IconBorderVertical,
  M as IconBoxAppColored,
  yr as IconBoxAppMonochrome,
  gi as IconBriefcaseAltFill,
  EP as IconBriefcaseAltOutline,
  ki as IconBrightness4Fill,
  VP as IconBrightness4Outline,
  Pi as IconBrightness5Fill,
  JP as IconBrightness5Outline,
  Gi as IconBrightness6Fill,
  zP as IconBrightness6Outline,
  Li as IconBrightness7Fill,
  qP as IconBrightness7Outline,
  V1 as IconBrokenImage,
  J1 as IconBrush,
  wi as IconBrushFill,
  ZP as IconBrushOutline,
  yi as IconBugFill,
  jP as IconBugOutline,
  z1 as IconBulb,
  Ji as IconBullhornFill,
  Ei as IconBullhornHighFill,
  XP as IconBullhornHighOutline,
  Vi as IconBullhornOffFill,
  $P as IconBullhornOffOutline,
  eD as IconBullhornOutline,
  zi as IconBusFill,
  tD as IconBusOutline,
  q1 as IconBusiness,
  ZU as IconCached,
  Z1 as IconCake,
  qi as IconCakeFill,
  lD as IconCakeOutline,
  j1 as IconCalendar,
  Zi as IconCalendarBlankFill,
  nD as IconCalendarBlankOutline,
  ji as IconCalendarClockFill,
  cD as IconCalendarClockOutline,
  Xi as IconCalendarCloseFill,
  sD as IconCalendarCloseOutline,
  $i as IconCalendarEditFill,
  mD as IconCalendarEditOutline,
  ex as IconCalendarEventFill,
  xD as IconCalendarEventOutline,
  tx as IconCalendarMonthFill,
  FD as IconCalendarMonthOutline,
  lx as IconCalendarPlusFill,
  hD as IconCalendarPlusOutline,
  nx as IconCalendarScheduledFill,
  OD as IconCalendarScheduledOutline,
  cx as IconCalendarTodayFill,
  gD as IconCalendarTodayOutline,
  sx as IconCalendarWeekFill,
  kD as IconCalendarWeekOutline,
  xx as IconCameraFill,
  jU as IconCameraFlip,
  GD as IconCameraOutline,
  mx as IconCameraPlusFill,
  PD as IconCameraPlusOutline,
  Fx as IconCarFill,
  LD as IconCarOutline,
  hx as IconCartFill,
  wD as IconCartOutline,
  XU as IconCartPlus,
  X1 as IconCategory,
  Ox as IconCellphoneAndroidFill,
  yD as IconCellphoneAndroidOutline,
  gx as IconCellphoneCogFill,
  ED as IconCellphoneCogOutline,
  Px as IconCellphoneFill,
  kx as IconCellphoneIosFill,
  VD as IconCellphoneIosOutline,
  JD as IconCellphoneOutline,
  $1 as IconCenterFocus,
  e5 as IconCenterFocusStrong,
  t5 as IconCenterTarget,
  l5 as IconChain,
  n5 as IconChannels,
  c5 as IconChannelsBox,
  s5 as IconChannelsColor,
  m5 as IconChannelsDuotone,
  ql as IconChannelsFileColored,
  cc as IconChannelsFileMonochrome,
  Gx as IconChartAreaFill,
  zD as IconChartAreaOutline,
  $U as IconChartAreaspline,
  tJ as IconChartBar,
  Lx as IconChartBarSquareFill,
  qD as IconChartBarSquareOutline,
  eJ as IconChartBarSquarePlus,
  wx as IconChartBubbleFill,
  ZD as IconChartBubbleOutline,
  lJ as IconChartDonut,
  nJ as IconChartGroupBar,
  mJ as IconChartLine,
  cJ as IconChartLineAlt,
  sJ as IconChartLineMelange,
  xJ as IconChartSsid,
  x5 as IconChat,
  F5 as IconCheck,
  yx as IconCheckAlarmFill,
  jD as IconCheckAlarmOutline,
  hJ as IconCheckAll,
  FJ as IconCheckAllOff,
  h5 as IconCheckBox,
  O5 as IconCheckBoxOutline,
  g5 as IconCheckBoxOutlineBlank,
  Ex as IconCheckCalendarFill,
  XD as IconCheckCalendarOutline,
  k5 as IconCheckCircle,
  Vx as IconCheckCircleFill,
  $D as IconCheckCircleOutline,
  Jx as IconCheckCloudFill,
  eG as IconCheckCloudOutline,
  zx as IconCheckDecagramFill,
  tG as IconCheckDecagramOutline,
  qx as IconCheckMapMarkerFill,
  lG as IconCheckMapMarkerOutline,
  Zx as IconCheckShieldFill,
  nG as IconCheckShieldOutline,
  OJ as IconCheckSm,
  gJ as IconCheckSync,
  Xx as IconCheckboxBlankFill,
  jx as IconCheckboxBlankMultipleFill,
  cG as IconCheckboxBlankMultipleOutline,
  sG as IconCheckboxBlankOutline,
  eI as IconCheckboxMarkedFill,
  $x as IconCheckboxMarkedMultipleFill,
  mG as IconCheckboxMarkedMultipleOutline,
  xG as IconCheckboxMarkedOutline,
  kJ as IconChevronDoubleDown,
  PJ as IconChevronDoubleLeft,
  GJ as IconChevronDoubleRight,
  LJ as IconChevronDoubleUp,
  wJ as IconChevronDown,
  tI as IconChevronDownCircleFill,
  FG as IconChevronDownCircleOutline,
  P5 as IconChevronLeft,
  lI as IconChevronLeftCircleFill,
  hG as IconChevronLeftCircleOutline,
  yJ as IconChevronLeftLarge,
  G5 as IconChevronRight,
  nI as IconChevronRightCircleFill,
  OG as IconChevronRightCircleOutline,
  EJ as IconChevronRightLarge,
  VJ as IconChevronUp,
  cI as IconChevronUpCircleFill,
  gG as IconChevronUpCircleOutline,
  sd as IconChina,
  sI as IconChromeFill,
  kG as IconChromeOutline,
  L5 as IconCircleBlank,
  LI as IconCircleFill,
  mI as IconCircleOffFill,
  PG as IconCircleOffOutline,
  qG as IconCircleOutline,
  xI as IconCircleSlice1Fill,
  GG as IconCircleSlice1Outline,
  FI as IconCircleSlice2Fill,
  LG as IconCircleSlice2Outline,
  hI as IconCircleSlice3Fill,
  wG as IconCircleSlice3Outline,
  OI as IconCircleSlice4Fill,
  yG as IconCircleSlice4Outline,
  gI as IconCircleSlice5Fill,
  EG as IconCircleSlice5Outline,
  kI as IconCircleSlice6Fill,
  VG as IconCircleSlice6Outline,
  PI as IconCircleSlice7Fill,
  JG as IconCircleSlice7Outline,
  GI as IconCircleSlice8Fill,
  zG as IconCircleSlice8Outline,
  A as IconClickupAppColored,
  Er as IconClickupAppMonochrome,
  ZJ as IconClipboard,
  JJ as IconClipboardCheck,
  zJ as IconClipboardClock,
  qJ as IconClipboardOff,
  yI as IconClockFill,
  wI as IconClockMultipleFill,
  ZG as IconClockMultipleOutline,
  jG as IconClockOutline,
  jJ as IconClockPlus,
  XJ as IconClockSend,
  w5 as IconClose,
  EI as IconCloseCalendarFill,
  XG as IconCloseCalendarOutline,
  VI as IconCloseCircleFill,
  $G as IconCloseCircleOutline,
  $J as IconCloseSm,
  eW as IconCloseXs,
  JI as IconCloudAltFill,
  eB as IconCloudAltOutline,
  zI as IconCloudDownloadFill,
  tB as IconCloudDownloadOutline,
  jI as IconCloudFill,
  qI as IconCloudOffFill,
  lB as IconCloudOffOutline,
  cB as IconCloudOutline,
  ZI as IconCloudUploadFill,
  nB as IconCloudUploadOutline,
  y5 as IconCode,
  tW as IconCodeOff,
  XI as IconCodeSquareFill,
  sB as IconCodeSquareOutline,
  E5 as IconCog,
  eF as IconCogFill,
  $I as IconCogMultipleFill,
  mB as IconCogMultipleOutline,
  xB as IconCogOutline,
  V5 as IconCogs,
  J5 as IconComment,
  z5 as IconCommute,
  q5 as IconCompareArrows,
  lF as IconCompassFill,
  tF as IconCompassOffFill,
  FB as IconCompassOffOutline,
  hB as IconCompassOutline,
  S as IconConfluenceAppColored,
  Vr as IconConfluenceAppMonochrome,
  cF as IconContentCopyFill,
  nF as IconContentCopyIosFill,
  OB as IconContentCopyIosOutline,
  gB as IconContentCopyOutline,
  sF as IconContentCutFill,
  kB as IconContentCutOutline,
  mF as IconContentSaveFill,
  PB as IconContentSaveOutline,
  lW as IconContrast,
  Z5 as IconCopy,
  j5 as IconCopyFile,
  X5 as IconCreate,
  cW as IconCrop,
  nW as IconCropRotate,
  mW as IconCrosshairs,
  xF as IconCrosshairsGpsFill,
  GB as IconCrosshairsGpsOutline,
  sW as IconCrosshairsOff,
  xW as IconCurrencyDollar,
  FF as IconCurrencyDollarCircleFill,
  LB as IconCurrencyDollarCircleOutline,
  FW as IconCurrencyEuro,
  $5 as IconCustomEmoji,
  hW as IconDataTableCell,
  OW as IconDataTableColumn,
  OF as IconDataTableFill,
  yB as IconDataTableOutline,
  gW as IconDataTableRow,
  hF as IconDataTableStarFill,
  wB as IconDataTableStarOutline,
  gF as IconDatabaseFill,
  EB as IconDatabaseOutline,
  eK as IconDelete,
  tK as IconDeleteCircle,
  kF as IconDeleteFill,
  VB as IconDeleteOutline,
  GF as IconDevicesFill,
  PF as IconDevicesOffFill,
  JB as IconDevicesOffOutline,
  zB as IconDevicesOutline,
  lK as IconDirectionsBike,
  b as IconDiscordAppColored,
  Jr as IconDiscordAppMonochrome,
  kW as IconDomain,
  LF as IconDoorMeetingRoomFill,
  qB as IconDoorMeetingRoomOutline,
  wF as IconDotsMessageFill,
  ZB as IconDotsMessageOutline,
  nK as IconDownload,
  cK as IconDraft,
  sK as IconDragHandleHorizontal,
  mK as IconDragHandleVertical,
  xK as IconDragHorizontal,
  PW as IconDragHorizontalAlt,
  FK as IconDragIndicator,
  LW as IconDragVertical,
  GW as IconDragVerticalAlt,
  D as IconDribbbleAppColored,
  zr as IconDribbbleAppMonochrome,
  hK as IconDrive,
  EF as IconDropFill,
  yF as IconDropOffFill,
  jB as IconDropOffOutline,
  XB as IconDropOutline,
  B as IconDropboxAppColored,
  qr as IconDropboxAppMonochrome,
  Zl as IconDropboxAppcenterFileColored,
  sc as IconDropboxAppcenterFileMonochrome,
  jl as IconDropboxBackupFileColored,
  mc as IconDropboxBackupFileMonochrome,
  Xl as IconDropboxCaptureFileColored,
  xc as IconDropboxCaptureFileMonochrome,
  $l as IconDropboxDocsendFileColored,
  Fc as IconDropboxDocsendFileMonochrome,
  ef as IconDropboxInvoiceFileColored,
  hc as IconDropboxInvoiceFileMonochrome,
  tf as IconDropboxPaperFileColored,
  Oc as IconDropboxPaperFileMonochrome,
  lf as IconDropboxPasswordsFileColored,
  gc as IconDropboxPasswordsFileMonochrome,
  nf as IconDropboxReplayFileColored,
  kc as IconDropboxReplayFileMonochrome,
  cf as IconDropboxSignFileColored,
  Pc as IconDropboxSignFileMonochrome,
  sf as IconDropboxTransferFileColored,
  Gc as IconDropboxTransferFileMonochrome,
  EW as IconEarth,
  wW as IconEarthOff,
  yW as IconEarthSearch,
  OK as IconEdit,
  VF as IconEditFill,
  $B as IconEditOutline,
  T as IconElisaAppColored,
  Zr as IconElisaAppMonochrome,
  gK as IconEmail,
  kK as IconEmojiOff,
  JF as IconEmojiOffFill,
  eL as IconEmojiOffOutline,
  PK as IconEmojiPlus,
  zF as IconEmoticonAngryFill,
  tL as IconEmoticonAngryOutline,
  qF as IconEmoticonCloseFill,
  lL as IconEmoticonCloseOutline,
  ZF as IconEmoticonDeadFill,
  nL as IconEmoticonDeadOutline,
  jF as IconEmoticonExcitedFill,
  cL as IconEmoticonExcitedOutline,
  nC as IconEmoticonFill,
  XF as IconEmoticonFrownFill,
  sL as IconEmoticonFrownOutline,
  $F as IconEmoticonHappyFill,
  mL as IconEmoticonHappyOutline,
  eC as IconEmoticonNeutralFill,
  xL as IconEmoticonNeutralOutline,
  OL as IconEmoticonOutline,
  tC as IconEmoticonPlusFill,
  FL as IconEmoticonPlusOutline,
  lC as IconEmoticonSadFill,
  hL as IconEmoticonSadOutline,
  sC as IconErlenmeyerFlaskFill,
  cC as IconErlenmeyerFlaskFullFill,
  gL as IconErlenmeyerFlaskFullOutline,
  kL as IconErlenmeyerFlaskOutline,
  GK as IconError,
  LK as IconEvent,
  wK as IconExitToApp,
  yK as IconExpand,
  EK as IconExpandLess,
  VK as IconExpandMore,
  JK as IconExternalLink,
  xC as IconEyeFill,
  mC as IconEyeOffFill,
  PL as IconEyeOffOutline,
  GL as IconEyeOutline,
  zK as IconFace,
  qK as IconFacebook,
  R as IconFacebookAppColored,
  jr as IconFacebookAppMonochrome,
  ZK as IconFastfood,
  jK as IconFavorite,
  XK as IconFavoriteBorder,
  v as IconFigmaAppColored,
  Xr as IconFigmaAppMonochrome,
  $K as IconFileAdobeAfterEffects,
  e0 as IconFileAdobeAnimate,
  t0 as IconFileAdobeIllustrator,
  l0 as IconFileAdobeIndesign,
  n0 as IconFileAdobePhotoshop,
  c0 as IconFileAdobePremiere,
  s0 as IconFileAodocs,
  m0 as IconFileBrokenFile,
  x0 as IconFileChannels,
  FC as IconFileCloseFill,
  LL as IconFileCloseOutline,
  F0 as IconFileCode,
  h0 as IconFileCodeColor,
  hC as IconFileDocumentFill,
  wL as IconFileDocumentOutline,
  O0 as IconFileDownload,
  OC as IconFileEyeFill,
  yL as IconFileEyeOutline,
  mf as IconFileFileColored,
  Lc as IconFileFileMonochrome,
  wC as IconFileFill,
  g0 as IconFileFolderColor,
  k0 as IconFileFolderFile,
  P0 as IconFileGDocs,
  G0 as IconFileGDocsColor,
  L0 as IconFileGDocsFile,
  w0 as IconFileGDraw,
  y0 as IconFileGDrawColor,
  E0 as IconFileGDrawFile,
  V0 as IconFileGForms,
  J0 as IconFileGFormsColor,
  z0 as IconFileGFormsFile,
  q0 as IconFileGSheets,
  Z0 as IconFileGSheetsColor,
  j0 as IconFileGSheetsFile,
  X0 as IconFileGSlides,
  $0 as IconFileGSlidesColor,
  ej as IconFileGSlidesFile,
  tj as IconFileGeneralFile,
  lj as IconFileGeneric,
  nj as IconFileGenericColor,
  cj as IconFileGif,
  sj as IconFileGifColor,
  mj as IconFileImageColor,
  xj as IconFileImgFile,
  Fj as IconFileLink,
  hj as IconFileLinkColor,
  Oj as IconFileMsAccess,
  gj as IconFileMsAccessColor,
  kj as IconFileMsExcel,
  Pj as IconFileMsExcelColor,
  Gj as IconFileMsOnenoteColor,
  Lj as IconFileMsPowerpoint,
  wj as IconFileMsPowerpointColor,
  yj as IconFileMsPublisher,
  Ej as IconFileMsPublisherColor,
  Vj as IconFileMsVisioColor,
  Jj as IconFileMsWord,
  zj as IconFileMsWordColor,
  gC as IconFileMultipleFill,
  EL as IconFileMultipleOutline,
  qj as IconFileMusic,
  Zj as IconFileMusicColor,
  jj as IconFileMusicFile,
  ZL as IconFileOutline,
  Xj as IconFilePages,
  $j as IconFilePdf,
  eY as IconFilePdfAlt,
  tY as IconFilePdfAltColor,
  lY as IconFilePdfColor,
  nY as IconFilePdfFile,
  cY as IconFilePeople,
  sY as IconFilePerson,
  mY as IconFilePersonColor,
  kC as IconFilePlusFill,
  VL as IconFilePlusOutline,
  PC as IconFileReplyFill,
  JL as IconFileReplyOutline,
  GC as IconFileSearchFill,
  zL as IconFileSearchOutline,
  xY as IconFileSound,
  FY as IconFileSoundColor,
  hY as IconFileSoundFile,
  OY as IconFileText,
  gY as IconFileTextColor,
  kY as IconFileTxtFile,
  PY as IconFileUpload,
  LC as IconFileUploadFill,
  qL as IconFileUploadOutline,
  GY as IconFileVideo,
  LY as IconFileVideoColor,
  wY as IconFileVideoFile,
  yY as IconFileZip,
  EY as IconFileZipColor,
  VY as IconFiles,
  JY as IconFilter,
  XW as IconFilterAlt,
  VW as IconFilterAltClose,
  JW as IconFilterAltEdit,
  zW as IconFilterAltMinus,
  qW as IconFilterAltOff,
  ZW as IconFilterAltPlus,
  jW as IconFilterAltStar,
  EC as IconFilterFill,
  zY as IconFilterList,
  yC as IconFilterOffFill,
  jL as IconFilterOffOutline,
  XL as IconFilterOutline,
  md as IconFinland,
  qY as IconFlag,
  VC as IconFlagFill,
  $L as IconFlagOutline,
  ZY as IconFlight,
  JC as IconFlowerFill,
  eT as IconFlowerOutline,
  jY as IconFolder,
  zC as IconFolderClockFill,
  tT as IconFolderClockOutline,
  XY as IconFolderColor,
  $Y as IconFolderDrive,
  Cf as IconFolderFileColored,
  yc as IconFolderFileMonochrome,
  XC as IconFolderFill,
  If as IconFolderMultipleFileColored,
  wc as IconFolderMultipleFileMonochrome,
  qC as IconFolderMultipleFill,
  lT as IconFolderMultipleOutline,
  ZC as IconFolderOffFill,
  nT as IconFolderOffOutline,
  sT as IconFolderOutline,
  e2 as IconFolderRecent,
  jC as IconFolderUploadFill,
  cT as IconFolderUploadOutline,
  eh as IconFoodFill,
  $C as IconFoodOffFill,
  mT as IconFoodOffOutline,
  xT as IconFoodOutline,
  th as IconFootballFill,
  FT as IconFootballOutline,
  t2 as IconFormatAlignCenter,
  l2 as IconFormatAlignJustify,
  n2 as IconFormatAlignLeft,
  c2 as IconFormatAlignRight,
  s2 as IconFormatBold,
  m2 as IconFormatClear,
  x2 as IconFormatColorFill,
  lh as IconFormatColorHighlightFill,
  hT as IconFormatColorHighlightOutline,
  F2 as IconFormatColorReset,
  h2 as IconFormatColorText,
  $W as IconFormatHeader1,
  ez as IconFormatHeader2,
  tz as IconFormatHeader3,
  lz as IconFormatHeader4,
  nz as IconFormatHeader5,
  cz as IconFormatHeader6,
  sz as IconFormatHorizontalAlignCenter,
  mz as IconFormatHorizontalAlignLeft,
  xz as IconFormatHorizontalAlignRight,
  Fz as IconFormatHorizontalExpandCenter,
  nh as IconFormatImageCaptionFill,
  OT as IconFormatImageCaptionOutline,
  ch as IconFormatImageCenterFill,
  gT as IconFormatImageCenterOutline,
  sh as IconFormatImageCoverAltFill,
  kT as IconFormatImageCoverAltOutline,
  mh as IconFormatImageCoverFill,
  PT as IconFormatImageCoverOutline,
  xh as IconFormatImageLeftAltFill,
  GT as IconFormatImageLeftAltOutline,
  Fh as IconFormatImageLeftFill,
  LT as IconFormatImageLeftOutline,
  hh as IconFormatImageOverflowFill,
  wT as IconFormatImageOverflowOutline,
  Oh as IconFormatImageRightAltFill,
  yT as IconFormatImageRightAltOutline,
  gh as IconFormatImageRightFill,
  ET as IconFormatImageRightOutline,
  O2 as IconFormatIndentDecrease,
  g2 as IconFormatIndentIncrease,
  k2 as IconFormatItalic,
  P2 as IconFormatLineSpacing,
  G2 as IconFormatListBulleted,
  L2 as IconFormatListNumbered,
  w2 as IconFormatQuote,
  kh as IconFormatQuoteCloseFill,
  VT as IconFormatQuoteCloseOutline,
  Ph as IconFormatQuoteOpenFill,
  JT as IconFormatQuoteOpenOutline,
  y2 as IconFormatSize,
  E2 as IconFormatStrikethrough,
  hz as IconFormatStrikethroughAlt,
  Oz as IconFormatTextPlus,
  gz as IconFormatTextSize,
  kz as IconFormatTextdirectionLToR,
  Pz as IconFormatTextdirectionRToL,
  Gz as IconFormatTitle,
  Lz as IconFormatUnderline,
  V2 as IconFormatUnderlined,
  wz as IconFormatVerticalAlignBottom,
  yz as IconFormatVerticalAlignCenter,
  Ez as IconFormatVerticalAlignTop,
  J2 as IconForum,
  Gh as IconForumFill,
  zT as IconForumOutline,
  Zz as IconForward,
  Vz as IconForward10,
  Jz as IconForward15,
  zz as IconForward30,
  qz as IconForward5,
  z2 as IconFrame,
  xd as IconFrance,
  H as IconFreshdeskAppColored,
  $r as IconFreshdeskAppMonochrome,
  q2 as IconFullscreen,
  Z2 as IconFullscreenExit,
  U as IconG2AppColored,
  et as IconG2AppMonochrome,
  j2 as IconGDocsColor,
  X2 as IconGDrawColor,
  $2 as IconGFormsColor,
  e3 as IconGSheetsColor,
  t3 as IconGSlideColor,
  Lh as IconGaugeHighFill,
  qT as IconGaugeHighOutline,
  wh as IconGaugeLowFill,
  ZT as IconGaugeLowOutline,
  Fd as IconGermany,
  l3 as IconGetApp,
  yh as IconGetAppFill,
  jT as IconGetAppOutline,
  n3 as IconGif,
  Eh as IconGifSquareFill,
  XT as IconGifSquareOutline,
  W as IconGithubAppColored,
  tt as IconGithubAppMonochrome,
  N as IconGitlabAppColored,
  lt as IconGitlabAppMonochrome,
  jz as IconGlobe,
  Q as IconGmailAppColored,
  nt as IconGmailAppMonochrome,
  c3 as IconGoogle,
  Mf as IconGoogleAcrobatFileColored,
  Ec as IconGoogleAcrobatFileMonochrome,
  K as IconGoogleAnalyticsAppColored,
  ct as IconGoogleAnalyticsAppMonochrome,
  Lo as IconGoogleAppColored,
  qt as IconGoogleAppMonochrome,
  Af as IconGoogleAudioFileColored,
  Vc as IconGoogleAudioFileMonochrome,
  Y as IconGoogleCalendarAppColored,
  st as IconGoogleCalendarAppMonochrome,
  _ as IconGoogleChatAppColored,
  mt as IconGoogleChatAppMonochrome,
  ro as IconGoogleChromeAppColored,
  Ft as IconGoogleChromeAppMonochrome,
  oo as IconGoogleChromeDinoAppColored,
  xt as IconGoogleChromeDinoAppMonochrome,
  Sf as IconGoogleCodeFileColored,
  Jc as IconGoogleCodeFileMonochrome,
  ao as IconGoogleDocsAppColored,
  ht as IconGoogleDocsAppMonochrome,
  bf as IconGoogleDocsFileColored,
  zc as IconGoogleDocsFileMonochrome,
  Df as IconGoogleDocumentsTextFileColored,
  qc as IconGoogleDocumentsTextFileMonochrome,
  Bf as IconGoogleDrawingsFileColored,
  Zc as IconGoogleDrawingsFileMonochrome,
  fo as IconGoogleDriveAppColored,
  Ot as IconGoogleDriveAppMonochrome,
  Jh as IconGoogleDriveFill,
  ew as IconGoogleDriveOutline,
  Vh as IconGoogleDrivePlusFill,
  $T as IconGoogleDrivePlusOutline,
  uo as IconGoogleFirebaseAppColored,
  gt as IconGoogleFirebaseAppMonochrome,
  Tf as IconGoogleFontFileColored,
  jc as IconGoogleFontFileMonochrome,
  po as IconGoogleFontsAppColored,
  kt as IconGoogleFontsAppMonochrome,
  mo as IconGoogleFormsAppColored,
  Pt as IconGoogleFormsAppMonochrome,
  Rf as IconGoogleFormsFileColored,
  Xc as IconGoogleFormsFileMonochrome,
  Fo as IconGoogleHangoutsAppColored,
  Lt as IconGoogleHangoutsAppMonochrome,
  xo as IconGoogleHangoutsMeetAppColored,
  Gt as IconGoogleHangoutsMeetAppMonochrome,
  vf as IconGoogleImageFileColored,
  $c as IconGoogleImageFileMonochrome,
  Hf as IconGoogleJamboardFileColored,
  ep as IconGoogleJamboardFileMonochrome,
  ho as IconGoogleKeepAppColored,
  wt as IconGoogleKeepAppMonochrome,
  Oo as IconGoogleMapsAppColored,
  yt as IconGoogleMapsAppMonochrome,
  Uf as IconGoogleMapsFileColored,
  tp as IconGoogleMapsFileMonochrome,
  go as IconGoogleMeetAppColored,
  Et as IconGoogleMeetAppMonochrome,
  Wf as IconGoogleMusicFileColored,
  lp as IconGoogleMusicFileMonochrome,
  Nf as IconGooglePdfFileColored,
  np as IconGooglePdfFileMonochrome,
  ko as IconGooglePlayAppColored,
  Vt as IconGooglePlayAppMonochrome,
  Qf as IconGoogleSheetsFileColored,
  cp as IconGoogleSheetsFileMonochrome,
  Po as IconGoogleSitesAppColored,
  Jt as IconGoogleSitesAppMonochrome,
  Kf as IconGoogleSitesFileColored,
  sp as IconGoogleSitesFileMonochrome,
  Go as IconGoogleSlidesAppColored,
  zt as IconGoogleSlidesAppMonochrome,
  Yf as IconGoogleSlidesFileColored,
  mp as IconGoogleSlidesFileMonochrome,
  _f as IconGoogleVideoAltFileColored,
  xp as IconGoogleVideoAltFileMonochrome,
  on as IconGoogleVideoFileColored,
  Fp as IconGoogleVideoFileMonochrome,
  rn as IconGoogleZipFileColored,
  hp as IconGoogleZipFileMonochrome,
  s3 as IconGraph,
  Xz as IconGraphicEq,
  m3 as IconGroup,
  x3 as IconGroupAdd,
  F3 as IconGroupAvatar,
  zh as IconGroupFill,
  tw as IconGroupOutline,
  h3 as IconGroupWork,
  qh as IconHammerScrewdriverFill,
  lw as IconHammerScrewdriverOutline,
  O3 as IconHangouts,
  wo as IconHappeoAppColored,
  Zt as IconHappeoAppMonochrome,
  g3 as IconHashtag,
  k3 as IconHashtagColor,
  Zh as IconHashtagSquareFill,
  nw as IconHashtagSquareOutline,
  jh as IconHeadphonesFill,
  cw as IconHeadphonesOutline,
  $h as IconHeadsetFill,
  Xh as IconHeadsetOffFill,
  sw as IconHeadsetOffOutline,
  mw as IconHeadsetOutline,
  eM as IconHeartFill,
  P3 as IconHeartLine,
  xw as IconHeartOutline,
  G3 as IconHelp,
  tM as IconHelpCircleFill,
  Fw as IconHelpCircleOutline,
  lM as IconHelpTooltipFill,
  hw as IconHelpTooltipOutline,
  yo as IconHibobAppColored,
  jt as IconHibobAppMonochrome,
  L3 as IconHidden,
  nM as IconHighlightMarkerFill,
  Ow as IconHighlightMarkerOutline,
  w3 as IconHistory,
  y3 as IconHome,
  cM as IconHomeFill,
  gw as IconHomeOutline,
  Eo as IconHubspotAppColored,
  Xt as IconHubspotAppMonochrome,
  E3 as IconImage,
  V3 as IconImageAlignCenter,
  J3 as IconImageAlignLeft,
  z3 as IconImageAlignRight,
  sM as IconImageAltFill,
  kw as IconImageAltOutline,
  mM as IconImageBrokenFill,
  Pw as IconImageBrokenOutline,
  q3 as IconImageColor,
  an as IconImageFileColored,
  Op as IconImageFileMonochrome,
  PM as IconImageFill,
  xM as IconImageFilterCenterFocusStrongFill,
  Gw as IconImageFilterCenterFocusStrongOutline,
  FM as IconImageFilterCenterFocusWeakFill,
  Lw as IconImageFilterCenterFocusWeakOutline,
  Z3 as IconImageHeroCover,
  j3 as IconImageHeroTop,
  X3 as IconImageInline,
  hM as IconImageMultipleFill,
  ww as IconImageMultipleOutline,
  Jw as IconImageOutline,
  $3 as IconImageOverflowCenter,
  e4 as IconImageOverflowLeft,
  t4 as IconImageOverflowRight,
  OM as IconImagePlusAltFill,
  yw as IconImagePlusAltOutline,
  gM as IconImagePlusFill,
  Ew as IconImagePlusOutline,
  l4 as IconImageResize,
  $z as IconImageResizeLg,
  eN as IconImageResizeSm,
  kM as IconImageStarFill,
  Vw as IconImageStarOutline,
  n4 as IconImageWrap,
  c4 as IconImgInlineFull,
  LM as IconInboxFill,
  GM as IconInboxMultipleFill,
  zw as IconInboxMultipleOutline,
  qw as IconInboxOutline,
  s4 as IconInfo,
  m4 as IconInfoRound,
  x4 as IconInfoRoundOutline,
  tN as IconInformation,
  wM as IconInformationCircleFill,
  Zw as IconInformationCircleOutline,
  yM as IconInformationShieldFill,
  jw as IconInformationShieldOutline,
  F4 as IconInsertCaption,
  h4 as IconInsertEmoticon,
  O4 as IconInsertLine,
  g4 as IconInsertTable,
  k4 as IconInstagram,
  Vo as IconInstagramAppColored,
  $t as IconInstagramAppMonochrome,
  Jo as IconIntercomAppColored,
  ea as IconIntercomAppMonochrome,
  hd as IconItaly,
  zo as IconJabberAppColored,
  ta as IconJabberAppMonochrome,
  Od as IconJapan,
  fn as IconJiraAccessRequestFileColored,
  gp as IconJiraAccessRequestFileMonochrome,
  qo as IconJiraAppColored,
  la as IconJiraAppMonochrome,
  un as IconJiraBugAltFileColored,
  kp as IconJiraBugAltFileMonochrome,
  pn as IconJiraBugFileColored,
  Pp as IconJiraBugFileMonochrome,
  dn as IconJiraConcernFileColored,
  Gp as IconJiraConcernFileMonochrome,
  xn as IconJiraDefectFileColored,
  Lp as IconJiraDefectFileMonochrome,
  Fn as IconJiraDesignTaskFileColored,
  wp as IconJiraDesignTaskFileMonochrome,
  hn as IconJiraDevelopmentTaskFileColored,
  yp as IconJiraDevelopmentTaskFileMonochrome,
  On as IconJiraDocumentationFileColored,
  Ep as IconJiraDocumentationFileMonochrome,
  gn as IconJiraEpicFileColored,
  Vp as IconJiraEpicFileMonochrome,
  kn as IconJiraImprovementFileColored,
  Jp as IconJiraImprovementFileMonochrome,
  Pn as IconJiraNewFeatureFileColored,
  zp as IconJiraNewFeatureFileMonochrome,
  Gn as IconJiraQuestionFileColored,
  qp as IconJiraQuestionFileMonochrome,
  Ln as IconJiraRemoveFeatureFileColored,
  Zp as IconJiraRemoveFeatureFileMonochrome,
  wn as IconJiraRequirementFileColored,
  jp as IconJiraRequirementFileMonochrome,
  yn as IconJiraSalesRequestFileColored,
  Xp as IconJiraSalesRequestFileMonochrome,
  En as IconJiraStoryFileColored,
  $p as IconJiraStoryFileMonochrome,
  Vn as IconJiraSubTaskFileColored,
  es as IconJiraSubTaskFileMonochrome,
  Jn as IconJiraSuggestionFileColored,
  ts as IconJiraSuggestionFileMonochrome,
  zn as IconJiraTaskFileColored,
  ls as IconJiraTaskFileMonochrome,
  EM as IconKeyboardFill,
  Xw as IconKeyboardOutline,
  Zo as IconKlarnaAppColored,
  na as IconKlarnaAppMonochrome,
  jo as IconKustomerAppColored,
  ca as IconKustomerAppMonochrome,
  VM as IconLabelAltFill,
  $w as IconLabelAltOutline,
  zM as IconLabelFill,
  JM as IconLabelOffFill,
  eR as IconLabelOffOutline,
  tR as IconLabelOutline,
  P4 as IconLanguage,
  qM as IconLaptopMacFill,
  lR as IconLaptopMacOutline,
  G4 as IconLaunch,
  L4 as IconLauncher,
  $M as IconLayerMultipleFill,
  ZM as IconLayerMultipleMinusFill,
  nR as IconLayerMultipleMinusOutline,
  jM as IconLayerMultipleOffFill,
  cR as IconLayerMultipleOffOutline,
  mR as IconLayerMultipleOutline,
  XM as IconLayerMultiplePlusFill,
  sR as IconLayerMultiplePlusOutline,
  w4 as IconLayout,
  eO as IconLeafFill,
  xR as IconLeafOutline,
  tO as IconLightbulbAltFill,
  FR as IconLightbulbAltOutline,
  cO as IconLightbulbFill,
  lO as IconLightbulbMelangeAltFill,
  hR as IconLightbulbMelangeAltOutline,
  nO as IconLightbulbMelangeFill,
  OR as IconLightbulbMelangeOutline,
  gR as IconLightbulbOutline,
  y4 as IconLike,
  E4 as IconLineChart,
  V4 as IconLink,
  lN as IconLinkClose,
  qn as IconLinkFileColored,
  ns as IconLinkFileMonochrome,
  J4 as IconLinkOff,
  nN as IconLinkPlus,
  Xo as IconLinkedinAppColored,
  sa as IconLinkedinAppMonochrome,
  z4 as IconLocalFlorist,
  q4 as IconLocation,
  Z4 as IconLocationCity,
  j4 as IconLocationOn,
  X4 as IconLock,
  mO as IconLockFill,
  $4 as IconLockOpen,
  sO as IconLockOpenFill,
  kR as IconLockOpenOutline,
  PR as IconLockOutline,
  cN as IconLogin,
  e6 as IconLogoAzure,
  t6 as IconLogoAzureColour,
  l6 as IconLogoFacebook,
  n6 as IconLogoFacebookColor,
  c6 as IconLogoGAnalytics,
  s6 as IconLogoGAnalyticsColor,
  m6 as IconLogoGCalendarColor,
  x6 as IconLogoGChat,
  F6 as IconLogoGChatColor,
  h6 as IconLogoGDrive,
  O6 as IconLogoGDriveColor,
  g6 as IconLogoGHangouts,
  k6 as IconLogoGHangoutsColor,
  P6 as IconLogoGMeet,
  G6 as IconLogoGMeetColor,
  L6 as IconLogoGmail,
  w6 as IconLogoInstagram,
  y6 as IconLogoInstagramColor,
  E6 as IconLogoJabberColor,
  V6 as IconLogoLinkedin,
  J6 as IconLogoLinkedinColor,
  z6 as IconLogoOktaColor,
  q6 as IconLogoSkype,
  Z6 as IconLogoSkypeColor,
  j6 as IconLogoSlackColor,
  X6 as IconLogoTwitter,
  $6 as IconLogoTwitterColor,
  e7 as IconLogoWebexColor,
  t7 as IconLogoWebexTeamsColor,
  l7 as IconLogoZoom,
  n7 as IconLogoZoomColor,
  sN as IconLogout,
  $o as IconLoopioAppColored,
  ma as IconLoopioAppMonochrome,
  xO as IconMagicWandFill,
  GR as IconMagicWandOutline,
  FO as IconMailArrowRightFill,
  LR as IconMailArrowRightOutline,
  hO as IconMailBadgeFill,
  wR as IconMailBadgeOutline,
  OO as IconMailCheckFill,
  yR as IconMailCheckOutline,
  GO as IconMailFill,
  gO as IconMailMinusCircleFill,
  ER as IconMailMinusCircleOutline,
  kO as IconMailOpenFill,
  VR as IconMailOpenOutline,
  zR as IconMailOutline,
  PO as IconMailPaperclipFill,
  JR as IconMailPaperclipOutline,
  ee as IconMailchimpAppColored,
  xa as IconMailchimpAppMonochrome,
  JO as IconMapFill,
  LO as IconMapMarkerCloseFill,
  qR as IconMapMarkerCloseOutline,
  wO as IconMapMarkerDropFill,
  ZR as IconMapMarkerDropOutline,
  mN as IconMapMarkerEdit,
  VO as IconMapMarkerFill,
  yO as IconMapMarkerOffFill,
  jR as IconMapMarkerOffOutline,
  $R as IconMapMarkerOutline,
  EO as IconMapMarkerPlusFill,
  XR as IconMapMarkerPlusOutline,
  ey as IconMapOutline,
  te as IconMastercardAppColored,
  Fa as IconMastercardAppMonochrome,
  xN as IconMath,
  ty as IconMathOutline,
  le as IconMazeAppColored,
  ha as IconMazeAppMonochrome,
  ne as IconMediumAppColored,
  Oa as IconMediumAppMonochrome,
  c7 as IconMelange,
  zO as IconMelangeFill,
  ly as IconMelangeOutline,
  s7 as IconMembers,
  m7 as IconMembersCircle,
  x7 as IconMenu,
  FN as IconMenuBurger,
  hN as IconMenuDown,
  qO as IconMenuDownCircleFill,
  ny as IconMenuDownCircleOutline,
  ON as IconMenuLeft,
  ZO as IconMenuLeftCircleFill,
  cy as IconMenuLeftCircleOutline,
  gN as IconMenuRight,
  jO as IconMenuRightCircleFill,
  sy as IconMenuRightCircleOutline,
  kN as IconMenuSwap,
  PN as IconMenuUp,
  XO as IconMenuUpCircleFill,
  my as IconMenuUpCircleOutline,
  $O as IconMessageAccountFill,
  xy as IconMessageAccountOutline,
  eA as IconMessageAltFill,
  Fy as IconMessageAltOutline,
  tA as IconMessageCheckFill,
  hy as IconMessageCheckOutline,
  lA as IconMessageFill,
  Oy as IconMessageOutline,
  ce as IconMetaAppColored,
  ga as IconMetaAppMonochrome,
  cA as IconMicrophoneFill,
  nA as IconMicrophoneOffFill,
  gy as IconMicrophoneOffOutline,
  ky as IconMicrophoneOutline,
  sA as IconMicroscopeFill,
  Py as IconMicroscopeOutline,
  F7 as IconMicrosoft,
  Zn as IconMicrosoftAccessAltFileColored,
  cs as IconMicrosoftAccessAltFileMonochrome,
  jn as IconMicrosoftAccessFileColored,
  ss as IconMicrosoftAccessFileMonochrome,
  Fe as IconMicrosoftAppColored,
  La as IconMicrosoftAppMonochrome,
  Xn as IconMicrosoftExcelAltFileColored,
  ms as IconMicrosoftExcelAltFileMonochrome,
  $n as IconMicrosoftExcelFileColored,
  xs as IconMicrosoftExcelFileMonochrome,
  eu as IconMicrosoftExchangeAltFileColored,
  Fs as IconMicrosoftExchangeAltFileMonochrome,
  tu as IconMicrosoftExchangeFileColored,
  hs as IconMicrosoftExchangeFileMonochrome,
  lu as IconMicrosoftFolderFileColored,
  Os as IconMicrosoftFolderFileMonochrome,
  nu as IconMicrosoftForms1FileColored,
  gs as IconMicrosoftForms1FileMonochrome,
  cu as IconMicrosoftFormsFileColored,
  ks as IconMicrosoftFormsFileMonochrome,
  su as IconMicrosoftLibraryFileColored,
  Ps as IconMicrosoftLibraryFileMonochrome,
  se as IconMicrosoftOnedriveAppColored,
  ka as IconMicrosoftOnedriveAppMonochrome,
  mu as IconMicrosoftOnenoteAltFileColored,
  Gs as IconMicrosoftOnenoteAltFileMonochrome,
  xu as IconMicrosoftOnenoteFileColored,
  Ls as IconMicrosoftOnenoteFileMonochrome,
  Fu as IconMicrosoftOutlookAltFileColored,
  ws as IconMicrosoftOutlookAltFileMonochrome,
  hu as IconMicrosoftOutlookFileColored,
  ys as IconMicrosoftOutlookFileMonochrome,
  Ou as IconMicrosoftPowerpointAltFileColored,
  Es as IconMicrosoftPowerpointAltFileMonochrome,
  gu as IconMicrosoftPowerpointFileColored,
  Vs as IconMicrosoftPowerpointFileMonochrome,
  ku as IconMicrosoftPublisherAltFileColored,
  Js as IconMicrosoftPublisherAltFileMonochrome,
  Pu as IconMicrosoftPublisherFileColored,
  zs as IconMicrosoftPublisherFileMonochrome,
  me as IconMicrosoftSharePointAppColored,
  Pa as IconMicrosoftSharePointAppMonochrome,
  xe as IconMicrosoftTeamsAppColored,
  Ga as IconMicrosoftTeamsAppMonochrome,
  Gu as IconMicrosoftWordAltFileColored,
  qs as IconMicrosoftWordAltFileMonochrome,
  Lu as IconMicrosoftWordFileColored,
  Zs as IconMicrosoftWordFileMonochrome,
  wu as IconMicrosoftYammerAltFileColored,
  js as IconMicrosoftYammerAltFileMonochrome,
  yu as IconMicrosoftYammerFileColored,
  Xs as IconMicrosoftYammerFileMonochrome,
  wN as IconMinus,
  h7 as IconMinusCircle,
  mA as IconMinusCircleFill,
  Gy as IconMinusCircleOutline,
  LN as IconMinusSearch,
  GN as IconMinusSearchAlt,
  he as IconMiroAppColored,
  wa as IconMiroAppMonochrome,
  O7 as IconMonetization,
  yN as IconMoreDotsHorizontal,
  EN as IconMoreDotsVertical,
  g7 as IconMoreHoriz,
  k7 as IconMoreVert,
  xA as IconMotorBikeFill,
  Ly as IconMotorBikeOutline,
  P7 as IconMsAccess,
  G7 as IconMsCalendar,
  L7 as IconMsDelve,
  w7 as IconMsDocumentLibrary,
  y7 as IconMsExcel,
  E7 as IconMsFolder,
  V7 as IconMsForms,
  J7 as IconMsOffice,
  z7 as IconMsOnedrive,
  q7 as IconMsOnenote,
  Z7 as IconMsOutlook,
  j7 as IconMsPowerpoint,
  X7 as IconMsProject,
  $7 as IconMsSharepoint,
  e9 as IconMsSway,
  t9 as IconMsTeams,
  l9 as IconMsVisio,
  n9 as IconMsWord,
  c9 as IconMyDraft,
  hA as IconNearMeFill,
  FA as IconNearMeOffFill,
  wy as IconNearMeOffOutline,
  yy as IconNearMeOutline,
  gd as IconNetherland,
  s9 as IconNewReleases,
  OA as IconNewspaperFill,
  Ey as IconNewspaperOutline,
  kd as IconNorway,
  gA as IconNoteCalendarFill,
  Vy as IconNoteCalendarOutline,
  PA as IconNoteFill,
  zy as IconNoteOutline,
  kA as IconNoteTextFill,
  Jy as IconNoteTextOutline,
  m9 as IconNotifications,
  x9 as IconNotificationsActive,
  F9 as IconNotificationsOff,
  h9 as IconNotificationsOn,
  Oe as IconOktaAppColored,
  ya as IconOktaAppMonochrome,
  O9 as IconOpenInNew,
  VN as IconOpenInNewOff,
  g9 as IconOrgChart,
  k9 as IconPage,
  JN as IconPageFirst,
  zN as IconPageLast,
  P9 as IconPageOld,
  G9 as IconPageSubpage,
  L9 as IconPagegroup,
  w9 as IconPagegroupAlt,
  y9 as IconPagegroupOld,
  E9 as IconPages,
  V9 as IconPagesBox,
  J9 as IconPagesColor,
  z9 as IconPagesDuotone,
  Eu as IconPagesFileColored,
  $s as IconPagesFileMonochrome,
  LA as IconPagesFill,
  Zy as IconPagesOutline,
  GA as IconPagesPlusFill,
  qy as IconPagesPlusOutline,
  q9 as IconPalette,
  wA as IconPaletteFill,
  jy as IconPaletteOutline,
  Z9 as IconPanelClose,
  j9 as IconPanelOpen,
  qN as IconPaperclip,
  yA as IconPaperclipFileFill,
  Xy as IconPaperclipFileOutline,
  EA as IconPauseFill,
  $y as IconPauseOutline,
  VA as IconPencilRulerFill,
  ev as IconPencilRulerOutline,
  ge as IconPendoAppColored,
  Ea as IconPendoAppMonochrome,
  X9 as IconPeople,
  $9 as IconPeopleAlt,
  e8 as IconPeopleAltDuotone,
  t8 as IconPeopleBox,
  l8 as IconPeopleColor,
  n8 as IconPeopleDuotone,
  ZN as IconPeopleExchange,
  c8 as IconPerson,
  s8 as IconPersonColor,
  JA as IconPersonalPlayFill,
  tv as IconPersonalPlayOutline,
  m8 as IconPhone,
  x8 as IconPhoneAndroid,
  qA as IconPhoneFill,
  zA as IconPhoneInTalkFill,
  lv as IconPhoneInTalkOutline,
  nv as IconPhoneOutline,
  F8 as IconPhoto,
  h8 as IconPhotoSizeSelectLarge,
  O8 as IconPinDrop,
  ZA as IconPinFill,
  cv as IconPinOutline,
  ke as IconPinterestAppColored,
  Va as IconPinterestAppMonochrome,
  jN as IconPlane,
  g8 as IconPlayArrow,
  jA as IconPlayCircleFill,
  k8 as IconPlayCircleFilled,
  sv as IconPlayCircleOutline,
  XA as IconPlayFill,
  mv as IconPlayOutline,
  eq as IconPlus,
  $A as IconPlusAlarmFill,
  xv as IconPlusAlarmOutline,
  eg as IconPlusBellFill,
  Fv as IconPlusBellOutline,
  tg as IconPlusCircleFill,
  hv as IconPlusCircleOutline,
  lg as IconPlusFileFill,
  Ov as IconPlusFileOutline,
  ng as IconPlusMapMarkerFill,
  gv as IconPlusMapMarkerOutline,
  $N as IconPlusSearch,
  XN as IconPlusSearchAlt,
  cg as IconPlusSquareFill,
  kv as IconPlusSquareOutline,
  sg as IconPlusVideoFill,
  Pv as IconPlusVideoOutline,
  Pd as IconPoland,
  tq as IconPoll,
  Pe as IconPollyAppColored,
  Ja as IconPollyAppMonochrome,
  Gd as IconPortugal,
  P8 as IconPrint,
  xg as IconPrinterFill,
  mg as IconPrinterOffFill,
  Gv as IconPrinterOffOutline,
  Lv as IconPrinterOutline,
  Ge as IconProductboardAppColored,
  za as IconProductboardAppMonochrome,
  lq as IconProgress1,
  nq as IconProgress2,
  cq as IconProgress3,
  sq as IconProgress4,
  mq as IconProgress5,
  xq as IconProgress6,
  Fq as IconProgress7,
  hq as IconProgress8,
  G8 as IconPublish,
  Fg as IconPublishFill,
  Oq as IconPublishOff,
  wv as IconPublishOutline,
  L8 as IconQuestion,
  hg as IconQuestionMapMarkerFill,
  yv as IconQuestionMapMarkerOutline,
  w8 as IconQuote,
  y8 as IconQuoteInline,
  E8 as IconQuoteWrap,
  gg as IconRadioBlankFill,
  Og as IconRadioBlankMultipleFill,
  Ev as IconRadioBlankMultipleOutline,
  Vv as IconRadioBlankOutline,
  Gg as IconRadioFill,
  Pg as IconRadioMarkedFill,
  kg as IconRadioMarkedMultipleFill,
  Jv as IconRadioMarkedMultipleOutline,
  zv as IconRadioMarkedOutline,
  qv as IconRadioOutline,
  gq as IconRedo,
  V8 as IconRefresh,
  J8 as IconRemove,
  z8 as IconRemoveCircle,
  q8 as IconRemoveCircleOutline,
  kq as IconRepeatAlt,
  Pq as IconReplace,
  Eq as IconReplay,
  Gq as IconReplay10,
  Lq as IconReplay15,
  wq as IconReplay30,
  yq as IconReplay5,
  Z8 as IconReply,
  Jq as IconReplyAlt,
  Vq as IconReplyAltReversed,
  Zq as IconReplyMultiple,
  qq as IconReplyMultipleAlt,
  zq as IconReplyMultipleAltReversed,
  j8 as IconResizeBottomRight,
  X8 as IconResizeDrag,
  jq as IconResizeHandle,
  Xq as IconRestore,
  $8 as IconRetweet,
  eX as IconReview,
  tX as IconReviewDraft,
  $q as IconRoadmap,
  lX as IconRocket,
  wg as IconRocketFill,
  Lg as IconRocketLaunchFill,
  Zv as IconRocketLaunchOutline,
  jv as IconRocketOutline,
  yg as IconRotate90DegreeCcwFill,
  Xv as IconRotate90DegreeCcwOutline,
  Eg as IconRotate90DegreeCwFill,
  $v as IconRotate90DegreeCwOutline,
  nX as IconRotateLeft,
  eQ as IconRotateLock,
  cX as IconRotateRight,
  tQ as IconRouteDirectionHorizontal,
  lQ as IconRouteDirectionVertical,
  sX as IconRss,
  mX as IconRssFeed,
  Le as IconRydooAppColored,
  qa as IconRydooAppMonochrome,
  Vu as IconSalesforceAccountFileColored,
  ed as IconSalesforceAccountFileMonochrome,
  we as IconSalesforceAppColored,
  Za as IconSalesforceAppMonochrome,
  Ju as IconSalesforceContactsFileColored,
  td as IconSalesforceContactsFileMonochrome,
  zu as IconSalesforceLeadFileColored,
  ld as IconSalesforceLeadFileMonochrome,
  qu as IconSalesforceOpportunityFileColored,
  nd as IconSalesforceOpportunityFileMonochrome,
  xX as IconScanner,
  Vg as IconScannerFill,
  eE as IconScannerOutline,
  Jg as IconScatterPlotFill,
  tE as IconScatterPlotOutline,
  zg as IconSchemaHorizontalFill,
  lE as IconSchemaHorizontalOutline,
  qg as IconSchemaVerticalFill,
  nE as IconSchemaVerticalOutline,
  nQ as IconScissorCut,
  jg as IconScriptFill,
  sE as IconScriptOutline,
  Zg as IconScriptTextFill,
  cE as IconScriptTextOutline,
  FX as IconSearch,
  cQ as IconSearchAlt,
  sQ as IconSelectDrag,
  hX as IconSend,
  Xg as IconSendClockFill,
  mE as IconSendClockOutline,
  $g as IconSendFill,
  xE as IconSendOutline,
  OX as IconSettings,
  gX as IconShadow,
  eS as IconShapeFill,
  FE as IconShapeOutline,
  tS as IconShareFill,
  mQ as IconShareIos,
  hE as IconShareOutline,
  kX as IconSharedDraft,
  lS as IconShieldAccountFill,
  OE as IconShieldAccountOutline,
  nS as IconShoppingBagFill,
  gE as IconShoppingBagOutline,
  ye as IconSkypeAppColored,
  ja as IconSkypeAppMonochrome,
  Ee as IconSlackAppColored,
  Xa as IconSlackAppMonochrome,
  PX as IconSliders,
  Ve as IconSliteAppColored,
  $a as IconSliteAppMonochrome,
  GX as IconSms,
  cS as IconSoccerballFill,
  kE as IconSoccerballOutline,
  LX as IconSocial,
  GQ as IconSort,
  wX as IconSortAlpha,
  yX as IconSortAlphaReverse,
  xQ as IconSortAlphabeticalAscending,
  FQ as IconSortAlphabeticalDescending,
  hQ as IconSortDown,
  OQ as IconSortLeft,
  gQ as IconSortReverse,
  kQ as IconSortRight,
  PQ as IconSortUp,
  Zu as IconSoundFileColored,
  cd as IconSoundFileMonochrome,
  Ld as IconSpain,
  Je as IconSpotifyAppColored,
  el as IconSpotifyAppMonochrome,
  sS as IconSquircleSmFill,
  PE as IconSquircleSmOutline,
  EX as IconStar,
  VX as IconStarBorder,
  mS as IconStarFill,
  LQ as IconStarHalfFull,
  GE as IconStarOutline,
  FS as IconStickyNoteFill,
  wE as IconStickyNoteOutline,
  xS as IconStickyNoteTextFill,
  LE as IconStickyNoteTextOutline,
  hS as IconStopFill,
  yE as IconStopOutline,
  JX as IconStream,
  zX as IconStrikethroughS,
  qX as IconStructure,
  ZX as IconSubdirectory,
  wQ as IconSubdirectoryArrowLeft,
  jX as IconSubdirectoryArrowRight,
  XX as IconSupervisedUserCircle,
  gS as IconSupervisedUserCircleFill,
  OS as IconSupervisedUserCircleOffFill,
  EE as IconSupervisedUserCircleOffOutline,
  VE as IconSupervisedUserCircleOutline,
  kS as IconSupervisedUserFill,
  JE as IconSupervisedUserOutline,
  yQ as IconSwapHorizontal,
  EQ as IconSwapSkew,
  VQ as IconSwapVertical,
  wd as IconSweden,
  JQ as IconSwipeLeft,
  zQ as IconSwipeRight,
  $X as IconSync,
  qQ as IconSyncAlt,
  ZQ as IconSyncLock,
  jQ as IconSyncOff,
  e_ as IconTag,
  PS as IconTagFill,
  zE as IconTagOutline,
  GS as IconTargetFill,
  qE as IconTargetOutline,
  LS as IconTennisFill,
  ZE as IconTennisOutline,
  yS as IconTestTubeFill,
  XE as IconTestTubeOutline,
  wS as IconTestTubeSearchFill,
  jE as IconTestTubeSearchOutline,
  nZ as IconText,
  ES as IconTextBookmarkFill,
  $E as IconTextBookmarkOutline,
  XQ as IconTextCheck,
  $Q as IconTextEdit,
  t_ as IconTextFormat,
  l_ as IconTextHighlight,
  eZ as IconTextLong,
  VS as IconTextMessageAltFill,
  eH as IconTextMessageAltOutline,
  JS as IconTextMessageFill,
  tH as IconTextMessageOutline,
  tZ as IconTextSearch,
  qS as IconTextSquareFill,
  zS as IconTextSquareMultipleFill,
  lH as IconTextSquareMultipleOutline,
  nH as IconTextSquareOutline,
  lZ as IconTextSquarePlus,
  ze as IconThinkificAppColored,
  tl as IconThinkificAppMonochrome,
  cZ as IconThreeDRotation,
  n_ as IconThumbDown,
  ZS as IconThumbDownFill,
  cH as IconThumbDownOutline,
  c_ as IconThumbUp,
  jS as IconThumbUpDownFill,
  sH as IconThumbUpDownOutline,
  XS as IconThumbUpFill,
  mH as IconThumbUpOutline,
  s_ as IconTime,
  ek as IconTimerFill,
  $S as IconTimerOffFill,
  xH as IconTimerOffOutline,
  FH as IconTimerOutline,
  m_ as IconToggleOff,
  tk as IconToggleOffFill,
  hH as IconToggleOffOutline,
  x_ as IconToggleOn,
  lk as IconToggleOnFill,
  OH as IconToggleOnOutline,
  nk as IconTooltipFill,
  gH as IconTooltipOutline,
  ck as IconTrainCarFill,
  kH as IconTrainCarOutline,
  sk as IconTrainFill,
  PH as IconTrainOutline,
  mk as IconTramFill,
  GH as IconTramOutline,
  sZ as IconTranslate,
  mZ as IconTrayDownload,
  xZ as IconTrayUpload,
  qe as IconTrelloAppColored,
  ll as IconTrelloAppMonochrome,
  F_ as IconTrendingDown,
  h_ as IconTrendingFlat,
  O_ as IconTrendingUp,
  Ze as IconTripactionAppColored,
  nl as IconTripactionAppMonochrome,
  je as IconTrustpilotAppColored,
  cl as IconTrustpilotAppMonochrome,
  g_ as IconTune,
  Fk as IconTuneAltFill,
  wH as IconTuneAltOutline,
  xk as IconTuneAltVerticalFill,
  LH as IconTuneAltVerticalOutline,
  FZ as IconTuneVertical,
  k_ as IconTwitter,
  Xe as IconTwitterAppColored,
  sl as IconTwitterAppMonochrome,
  $e as IconTypeformAppColored,
  ml as IconTypeformAppMonochrome,
  P_ as IconUnarchive,
  hk as IconUnarchiveFill,
  yH as IconUnarchiveOutline,
  hZ as IconUndo,
  OZ as IconUnfoldLessHorizontal,
  gZ as IconUnfoldLessVertical,
  kZ as IconUnfoldMoreHorizontal,
  PZ as IconUnfoldMoreVertical,
  yd as IconUnitedKingdom,
  Ed as IconUnitedStates,
  G_ as IconUnpublish,
  L_ as IconUnsplash,
  er as IconUnsplashAppColored,
  xl as IconUnsplashAppMonochrome,
  w_ as IconUpdate,
  y_ as IconUpload,
  E_ as IconUser,
  V_ as IconUserAdd,
  J_ as IconUsers,
  gk as IconVideoFill,
  Ok as IconVideoOffFill,
  EH as IconVideoOffOutline,
  VH as IconVideoOutline,
  z_ as IconVideocam,
  kk as IconViewDashboardAltFill,
  JH as IconViewDashboardAltOutline,
  Gk as IconViewDashboardFill,
  qH as IconViewDashboardOutline,
  Pk as IconViewDashboardSpaceFill,
  zH as IconViewDashboardSpaceOutline,
  yk as IconViewGridFill,
  Lk as IconViewGridModuleFill,
  ZH as IconViewGridModuleOutline,
  XH as IconViewGridOutline,
  wk as IconViewGridPlusFill,
  jH as IconViewGridPlusOutline,
  q_ as IconViewList,
  Z_ as IconViewModule,
  j_ as IconViewQuilt,
  X_ as IconViewStream,
  tr as IconVisaAppColored,
  Fl as IconVisaAppMonochrome,
  $_ as IconVisibility,
  e$ as IconVisibilityOff,
  t$ as IconVisibilityOn,
  l$ as IconVisible,
  Ek as IconVolumeHighFill,
  $H as IconVolumeHighOutline,
  Vk as IconVolumeLowFill,
  eV as IconVolumeLowOutline,
  Jk as IconVolumeMuteFill,
  tV as IconVolumeMuteOutline,
  zk as IconVolumeOffFill,
  lV as IconVolumeOffOutline,
  Vd as IconWales,
  qk as IconWalletFill,
  nV as IconWalletOutline,
  n$ as IconWarning,
  Zk as IconWeatherNightFill,
  cV as IconWeatherNightOutline,
  jk as IconWeatherSunnyFill,
  sV as IconWeatherSunnyOutline,
  cr as IconWebexAppColored,
  gl as IconWebexAppMonochrome,
  lr as IconWebexMeetingsAppColored,
  hl as IconWebexMeetingsAppMonochrome,
  nr as IconWebexTeamsAppColored,
  Ol as IconWebexTeamsAppMonochrome,
  c$ as IconWidgetBreadcrumb,
  s$ as IconWidgetButton,
  m$ as IconWidgetChannelFeed,
  x$ as IconWidgetFiles,
  F$ as IconWidgetImage,
  h$ as IconWidgetLinkList,
  O$ as IconWidgetSpacer,
  g$ as IconWidgetText,
  k$ as IconWidgetVideo,
  LZ as IconWifi,
  GZ as IconWifiOff,
  Xk as IconWorkspaceFill,
  mV as IconWorkspaceOutline,
  sr as IconYoutubeAppColored,
  kl as IconYoutubeAppMonochrome,
  mr as IconZendeskAppColored,
  Pl as IconZendeskAppMonochrome,
  xr as IconZoomAppColored,
  Gl as IconZoomAppMonochrome,
  P$ as IconZoomIn,
  G$ as IconZoomOut,
  L$ as LogoGoogleFonts,
  w$ as PagesPlus,
  y$ as WebsiteNotActive,
  E$ as WikiNotActive
};
